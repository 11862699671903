import styled from 'styled-components';

import { colours } from '../../../constants/colours';

export interface IPriceTagProps {
    bgColorHexCode: string;
}

export const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
`;

export const DetailsContainer = styled.div`
    display: flex;
    max-width: 1225px;
    width: 100%;
`;

export const PicturesGroup = styled.div`
    display: block;
    max-width: 37.5rem;
    width: 100%;
    height: 40rem;
    max-height: 40rem;
`;

export const CarContainer = styled.div`
    display: flex;
    justify-content: center;
    max-width: 37.5rem;
    max-height: 20rem;
    width: 100%;

    img {
        width: 100%;
        max-width: 37.5rem;
        height: 100%;
        max-height: 20rem;
    }
`;

export const CubesContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
    img {
        width: 100%;
        max-width: 37.5rem;
        height: 100%;
        max-height: 20rem;
        object-fit: contain;
    }
`;

export const DetailsGroup = styled.div`
    display: block;
    max-width: 37.5rem;
    width: 100%;
`;

export const CarName = styled.h3`
    color: ${colours.black};
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 0;
    margin-bottom: 2rem;
`;

export const PriceTagWrapper = styled.div`
    display: flex;
    margin-bottom: 2rem;
    max-width: 15rem;
`;

export const PriceTag = styled.div<IPriceTagProps>`
    display: flex;
    justify-content: left;
    width: fit-content;
    border-radius: 0.25rem;
    padding: 4px 7px;
    background-color: ${({ bgColorHexCode }: IPriceTagProps) => bgColorHexCode};
`;

export const Price = styled.h3`
    margin: 0;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 32px;
    color: ${colours.white};
`;

export const FormContainer = styled.div`
    display: grid;
    max-width: 601px;
    width: 100%;

    grid-template-columns: repeat(auto-fit, minmax(190px, max-content));
    column-gap: 10px;
    row-gap: 24px;
    justify-content: left;
    margin: 0;
    padding: 0;
`;

export const ContractIncludesText = styled.p`
    margin: 2rem 0;
    max-width: 37.5rem;
    font-weight: 400;
    font-size: 12px;
    line-height: 1rem;
    color: ${colours.darkGrey};
`;

export const AccordionContainer = styled.div`
    max-width: 37.5rem;
    align-items: center;
    margin-bottom: 2.5rem;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 37.5rem;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 1rem;

    & :first-child {
        margin-right: 1rem;
    }
`;

export const CustomButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 290px;
    width: 100%;
    background: ${colours.darkGrey};
    color: ${colours.white};
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 1px;
    cursor: pointer;

    &:hover {
        background-color: ${colours.darkerGrey};
    }

    svg {
        margin-left: 0.75rem;
        margin-right: 0 !important;
    }
`;

export const BackButtonWrapper = styled.div`
    max-width: 76.5rem;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    color: ${colours.blue};

    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    svg {
        width: 1.5rem;
        margin-right: 0.5rem;
    }
`;

export const ModifyButtonCustomStyle = { width: '17.5rem', height: '40px' };

export const PriceInfoText = styled.p`
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    color: ${colours.darkGrey};
`;

export const LegalNotice = styled.span`
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${colours.darkGrey};
`;
export const PrintButtonCustomStyle = {
    background: String(colours.defaultPriceTagColor),
    width: '17.5rem',
    height: '40px',
};
