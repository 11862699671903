import React from 'react';

import { SelectContains, SelectGroupContainer } from './styles';

import type { IFullWidthProp } from '../../types/index';

interface IFormProps extends IFullWidthProp {
    children: unknown;
}

const Form: React.FC<IFormProps> = ({ fullWidth, children }) => (
    <SelectContains>
        <SelectGroupContainer fullWidth>{children}</SelectGroupContainer>
    </SelectContains>
);

export default Form;
