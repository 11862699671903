import React, { useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { useDropzone } from 'react-dropzone';

import { Alert, Snackbar } from '@mui/material';

import { FileList } from '../FileList';
import {
    uploadImageFiles,
    uploadCSVFile,
    selectFileError,
    selectFileStatus,
    clearFileStatus,
    checkCSVUpload,
} from '../../slices/fileSlice';

// MUI

import {
    Container,
    DropZoneWrapper,
    ButtonContainer,
    ButtonsContainer,
    WarningMessage,
} from './styles';
import { AcceptedFileTypes, SliceStatus } from '../../types';
import { normalizeName } from '../../utils/s3-images-helper';

interface IProps {
    fileType: string;
}

const FileUpload: React.FunctionComponent<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const fileError = useSelector(selectFileError);
    const fileStatus = useSelector(selectFileStatus);
    const { fileType } = props;
    const isImageUpload = fileType === AcceptedFileTypes.PNG;
    const maxFiles = isImageUpload ? 200 : 1;
    const [files, setFiles] = useState<File[]>([]);
    const filesAdded = () => files.length > 0;

    const handleClose = () => {
        dispatch(clearFileStatus());
    };

    const onDrop = useCallback((droppedFiles: File[]) => setFiles(droppedFiles), [setFiles]);
    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        onDrop,
        disabled: files.length === maxFiles,
        maxFiles,
        ...(isImageUpload && { accept: { 'image/png': ['.png'] } }),
        ...(!isImageUpload && { accept: { 'text/csv': ['.csv'] } }),
    });

    const uploadFiles = () => {
        const now = new Date().toString();
        if (files.length > 0 && isImageUpload) {
            dispatch(uploadImageFiles(files));
        } else {
            const fileName = normalizeName(files[0].name);
            dispatch(uploadCSVFile({ files, now }));
            dispatch(checkCSVUpload({ fileName, now }));
        }
        setFiles([]);
    };
    const cancelFiles = () => {
        setFiles([]);
    };

    return (
        <Container data-testid="image-upload">
            <DropZoneWrapper
                droppedFile={files.length}
                {...getRootProps({ className: 'dropzone' })}
            >
                {/* eslint-disable-next-line react/jsx-props-no-spreading -- from useDropzone guide no danger of unnecessary props--*/}
                <input {...getInputProps()} />
                {!(files.length === maxFiles) && <h2>{t('pages.admin.fileUpload.message')}</h2>}
                {isImageUpload && <h3>{t('pages.admin.fileUpload.replacementMessage')}</h3>}
                <aside>
                    {files.length < 1 ? (
                        <p>{t('pages.admin.fileUpload.noFilesToUpload')}</p>
                    ) : (
                        <p>
                            {/* Display message according to the number of files to upload */}
                            {files.length === 1
                                ? `${t('pages.admin.fileUpload.filesToUpload')}: ${files.length}`
                                : `${t('pages.admin.fileUpload.multipleFilesToUpload')}: ${
                                      files.length
                                  }`}
                        </p>
                    )}
                    <FileList files={files} />
                    {fileRejections.length > 0 && (
                        <WarningMessage>
                            {fileRejections[0].errors[0].code === 'file-invalid-type'
                                ? t('pages.admin.fileUpload.incorrectFileFormat')
                                : t('pages.admin.fileUpload.tooManyFilesError', {
                                      maxFiles,
                                  })}
                        </WarningMessage>
                    )}
                </aside>
            </DropZoneWrapper>
            <ButtonsContainer>
                <ButtonContainer onClick={uploadFiles} disabled={!filesAdded()}>
                    {t('pages.admin.fileUpload.uploadButton')}
                </ButtonContainer>
                <ButtonContainer onClick={cancelFiles} disabled={!filesAdded()}>
                    {t('pages.admin.actionButtons.cancel')}
                </ButtonContainer>
            </ButtonsContainer>
            <Snackbar
                open={fileStatus === SliceStatus.SUCCESS}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {t('pages.admin.fileUpload.fileUploadSuccess')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={Boolean(fileStatus === SliceStatus.FAILED)}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                {fileError?.errorCode ? (
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {t(`global.errors.${fileError.errorCode}`, { message: fileError.message })}
                    </Alert>
                ) : (
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {t('pages.admin.fileUpload.fileUploadError', {
                            message: fileError?.message,
                        })}
                    </Alert>
                )}
            </Snackbar>
        </Container>
    );
};

export default FileUpload;
