import React from 'react';

import { Container, DotsContainer, Text, Dot } from './styles';

interface IProps {
    text?: string;
}

const defaultProps: IProps = {
    text: '',
};

const Loader: React.FunctionComponent<IProps> = (props: IProps) => {
    const textElement = props.text ? <Text>{props.text}</Text> : null;

    return (
        <Container data-testid="loader">
            <DotsContainer>
                <Dot />
                <Dot />
                <Dot />
            </DotsContainer>
            {textElement}
        </Container>
    );
};

Loader.defaultProps = defaultProps;

export default Loader;
