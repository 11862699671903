import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: grid;
    max-width: 1224px;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(190px, max-content));
    gap: 16px;
    justify-content: center;
    margin-top: 24px;
    position: relative;
`;
