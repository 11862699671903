import React from 'react';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import type { SelectProps } from '@mui/material';
import { MenuItem } from '@mui/material';

import { StyledSelect, StyledInputLabel, SelectContainer } from './styles';

import type { IDropdownTypes, IFullWidthProp } from '../../types/index';

interface ISelectProps extends SelectProps, IFullWidthProp {
    value: string | number;
    setValue: (_: string) => void;
    id: string;
    selectLabel: string;
    options: IDropdownTypes[];
}

const Select: React.FC<ISelectProps> = ({
    value,
    setValue,
    id,
    selectLabel,
    options,
    fullWidth,
    ...otherProps
}) => (
    <SelectContainer fullWidth>
        <StyledInputLabel id={id}>{selectLabel}</StyledInputLabel>
        <StyledSelect
            id="select"
            IconComponent={KeyboardArrowDownRoundedIcon}
            value={value}
            native={false}
            variant="standard"
            onChange={(selected) => setValue(String(selected.target.value))}
            {...otherProps}
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </StyledSelect>
    </SelectContainer>
);

export default Select;
