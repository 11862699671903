import React, { useState, useEffect } from 'react';

// Assets
import { ChevronUpOutline } from '../../assets/icons';

// Components
import MarkdownRenderer from '../MarkdownRenderer/MarkdownRenderer';

// Types
import type { IAccordionProps } from './types';

// Styles
import {
    AccordionContainer,
    AccordionWrapper,
    HeaderName,
    HeaderNameText,
    ChevronUpOutlineWrapper,
    ContentText,
} from './styles';

const Accordion = ({ defaultExpanded = false, service, accordionOpened }: IAccordionProps) => {
    const { name, hexCode, description } = service;
    const displayService = { ...service, expanded: !name ? true : Boolean(defaultExpanded) };

    const [isExpanded, setIsExpanded] = useState(!name ? true : defaultExpanded);
    useEffect(() => {
        if (service.name && service.description) {
            setIsExpanded(defaultExpanded);
        }
    }, [defaultExpanded, service.description, service.name]);

    const toggleExpand = () => {
        const updatedDisplayService = { ...displayService, expanded: !isExpanded };
        if (accordionOpened) {
            accordionOpened(updatedDisplayService);
            setIsExpanded(!isExpanded);
        } else {
            setIsExpanded(!isExpanded);
        }
    };

    return (
        <AccordionContainer>
            <AccordionWrapper>
                {name ? (
                    <HeaderName
                        colorHexCode={hexCode}
                        role="button"
                        tabIndex={0}
                        onClick={toggleExpand}
                    >
                        <HeaderNameText>
                            <MarkdownRenderer content={name} />
                        </HeaderNameText>

                        {description ? (
                            <ChevronUpOutlineWrapper isExpanded={isExpanded}>
                                <ChevronUpOutline />
                            </ChevronUpOutlineWrapper>
                        ) : null}
                    </HeaderName>
                ) : null}
                {isExpanded && description ? (
                    <ContentText>
                        {description ? <MarkdownRenderer content={description} /> : null}
                    </ContentText>
                ) : null}
            </AccordionWrapper>
        </AccordionContainer>
    );
};

export default Accordion;
