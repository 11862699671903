import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// Slices
import { selectCarContracts } from '../../../../../slices/carsSlice';
import { updateSelections } from '../../../../../slices/contractsSlice';
import { selectSelectedUpsell } from '../../../../../slices/upsellsSlice';

// Components
import Select from '../../../../../components/Select/Select';

// Types
import { ContractFields } from '../../../../../types/contracts';
import type { IContractSelections } from '../../../../../types/contracts';

// Utils
import { renderSelectedMileage } from '../../../../../utils/formatDropdownValue';
import { filterMileage } from '../../../../../utils/contract-helper';

export interface FormProps {
    formSelections: IContractSelections | null;
}

const SelectMileage: React.FC<FormProps> = (props: FormProps) => {
    const { formSelections } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Store
    const availableCarContracts = useSelector(selectCarContracts);
    const upsell = useSelector(selectSelectedUpsell);

    const availableMileage = useMemo(
        () => filterMileage(availableCarContracts, upsell, formSelections, t),
        [availableCarContracts, upsell, formSelections, t],
    );

    const handleContractFieldSelection = (field: string, value: string) => {
        dispatch(updateSelections({ field, value }));
    };

    return (
        <Select
            id="selectMileage"
            selectLabel={t(`pages.tierTool.cars.mileageLabel`)}
            value={formSelections?.mileage ? String(formSelections.mileage) : ''}
            options={availableMileage}
            setValue={(mileage) =>
                handleContractFieldSelection(ContractFields.mileage, String(mileage))
            }
            disabled={!formSelections?.duration || formSelections.duration === ''}
            renderValue={(selected) => {
                if (selected) {
                    return renderSelectedMileage(selected, formSelections?.duration, t);
                }
                return t(`global.dropdown.default`);
            }}
            displayEmpty
        />
    );
};

export default SelectMileage;
