import styled, { css } from 'styled-components';
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';

import { colours } from '../../constants/colours';

interface IStepperNavigationBarProps {
    disabled?: boolean;
}

export const StyledStepper = styled(Stepper)`
    display: flex;
    width: 100%;
    max-width: 1224px;
    border: none;
    margin: auto;
    margin-bottom: 60px;

    .stepCompletedIcon {
        color: ${colours.success};
    }

    .MuiStepConnector-line {
        border: none;
    }

    .MuiStepContent-root {
        margin: 0;
        padding: 0;
        border: none;
    }

    /* MUI Class for the select component */
    .MuiInputBase-root {
        height: 40px;
        border: none;

        /* Removing the border before selecting an option */
        &:before {
            border: none;
        }

        /* Removing the border after selecting an option */
        &:after {
            border: none;
        }
    }

    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
        color: ${colours.blue};
    }

    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
        color: ${colours.blue};
    }

    .css-117w1su-MuiStepIcon-text {
        font-weight: 700;
        font-size: 16px;
    }

    @media (max-width: 1260px) {
        #stepper {
            padding: 0 24px;
        }
    }
`;

export const StyledStep = styled(Step)`
    width: 100%;
`;

export const StyledStepLabel = styled(StepLabel)`
    display: flex;
`;

export const StepperNavigationBar = styled.div<IStepperNavigationBarProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 1rem;

    color: ${colours.blue};
    font-weight: 700;
    font-size: 16px;

    ${(props) =>
        props.disabled &&
        css`
            color: ${colours.mediumGrey};
        `}
`;

export const StepperLabelContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    p {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    svg {
        margin-left: 1rem;
        height: 24px;
    }
`;

export const StepText = styled.p``;

export const SelectedCarContainer = styled.div`
    margin-top: 24px;
`;

export const StyledStepContent = styled(StepContent)`
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
    border: none;
    max-width: 1224px;
    width: 100%;
`;

export const StepperArrowsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    cursor: pointer;

    // Modify Label left of arrow
    p {
        display: flex;
        height: 24px;
        border: none;
        margin-right: 1rem;
        font-size: 16px;
    }

    // Arrow icons
    svg {
        cursor: pointer;
    }
`;

export const Separator = styled.div`
    display: flex;
    width: 100%;
    max-width: 1224px;
    margin: 0 auto;
    background: ${colours.lightGrey};
    height: 1px;
    margin-top: 8px;
`;
