import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { axiosInstance } from '../axiosInstance';

import type { RootState } from '../store';
import type { IUser } from '../types/users/index';

import type { IError } from '../types/errors/index';

import { BACKEND_ROUTES } from '../constants/api.routes';

export interface IAuthState {
    user: IUser | null;
    errors: IError | null;
    loading: boolean;
}

export interface IResponseFailure extends IError {}

export interface ILoginResponseSuccess {
    data: {
        user: IUser;
        token: string;
    };
}

export interface IWhoAmIResponseSuccess {
    data: IUser;
}

export const initialState: IAuthState = {
    user: null,
    errors: null,
    loading: false,
};

export const loginUser = createAsyncThunk(
    'auth/login',
    (params: { email: string; password: string }, { dispatch }) => {
        axiosInstance
            .post<ILoginResponseSuccess>(BACKEND_ROUTES.LOGIN, {
                email: params.email,
                password: params.password,
            })
            .then((response) => {
                localStorage.setItem('api_token', response.data.data.token);
                dispatch(setUserAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

// When user logs out, clear api token from local storage and set store to initial state
export const logoutUser = createAsyncThunk('auth/logout', (_, { dispatch }) => {
    localStorage.removeItem('api_token');
    dispatch(clearUserAction());
});

export const whoamiRequest = createAsyncThunk('auth/who_am_i', (_, { dispatch }) => {
    axiosInstance
        .get<IWhoAmIResponseSuccess>(BACKEND_ROUTES.WHO_AM_I, {})
        .then((response) => {
            dispatch(setUserFromTokenAction(response.data));
        })
        .catch((err: IResponseFailure) => {
            localStorage.removeItem('api_token');
            dispatch(setErrorsAction(err));
        });
});

export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserAction(state, action: PayloadAction<ILoginResponseSuccess>) {
            state.user = action.payload.data.user;
            state.loading = false;
            state.errors = null;
        },
        setUserFromTokenAction(state, action: PayloadAction<IWhoAmIResponseSuccess>) {
            state.user = action.payload.data;
            state.loading = false;
            state.errors = null;
        },
        setErrorsAction(state, action: PayloadAction<IResponseFailure | null>) {
            state.user = null;
            state.errors = action.payload;
            state.loading = false;
        },
        clearUserAction(state) {
            state.user = null;
            state.loading = false;
            state.errors = null;
        },
    },
});

export const selectUser = (state: RootState) => state.auth.user;

export const { setUserAction, setUserFromTokenAction, setErrorsAction, clearUserAction } =
    slice.actions;

export default slice.reducer;
