import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// MUI
import { FormControl, Select, MenuItem, InputLabel, TextField } from '@mui/material';

// MUI Types
import type { SelectChangeEvent } from '@mui/material';

// Slices
import { logoutUser, selectUser } from '../../slices/authSlice';
import {
    fetchBrands,
    selectBrands,
    selectBrand,
    setBrandAction,
    doUpdateBrandColor,
    selectBrandLoading,
} from '../../slices/brandSlice';

import { selectFileLoading, selectFileStatus } from '../../slices/fileSlice';
import { selectUpsellsLoading, selectUpsellsErrors } from '../../slices/upsellsSlice';
import { selectServiceLoading, selectServicesErrors } from '../../slices/servicesSlice';

// Assets

// Constants

// Components
import ActionButton from '../../components/ActionButton/ActionButton';
import BackdropLoader from '../../components/BackdropLoader/BackdropLoader';
import FileUpload from '../../components/FileUpload';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';

// SubComponents
import UpsellsList from './UpsellsList/UpsellList';

// Helpers
import { parseNull } from './helper';

// Styles
import {
    Stepper,
    Container,
    Section,
    ButtonContainer,
    PageHeadlineContainer,
    PageTitle,
    PageSubTitle,
    FormContainer,
    FormWrapper,
    InputsLineContainer,
    InputWrapper,
    ActionButtonsContainer,
} from './styles';

// Types
import { TranslationKeys } from '../../types/errors/index';

const Admin: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // States
    const user = useSelector(selectUser);
    const allBrands = useSelector(selectBrands);
    const selectedBrand = useSelector(selectBrand);
    const fileLoading = useSelector(selectFileLoading);
    const fileUploading = useSelector(selectFileStatus);
    const brandLoading = useSelector(selectBrandLoading);
    const upsellLoading = useSelector(selectUpsellsLoading);
    const serviceLoading = useSelector(selectServiceLoading);

    const upsellSelectionError = useSelector(selectUpsellsErrors);
    const serviceSelectionError = useSelector(selectServicesErrors);

    const [brandColorModified, setBrandColorModified] = useState<boolean>(false);

    // Refs
    const brandColorInputRef = React.createRef<HTMLInputElement>();

    // Helpers

    // Effects
    useEffect(() => {
        if (!user) {
            navigate('/login', {});
        }
        const API_TOKEN = localStorage.getItem('api_token');
        if (!API_TOKEN) {
            dispatch(logoutUser());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- NO DISPATCH, NAVIGATE
    }, [user]);

    useEffect(() => {
        if (!allBrands) {
            dispatch(fetchBrands());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- NO DISPATCH
    }, [allBrands]);

    useEffect(() => {
        if (selectedBrand) {
            setBrandColorModified(false);
        }
    }, [selectedBrand]);

    // Handlers
    const logout = () => {
        dispatch(logoutUser());
    };

    const handleBrandSelection = (event: SelectChangeEvent) => {
        const foundBrand = allBrands?.find((brand) => brand.id === Number(event.target.value));
        if (foundBrand) {
            if (brandColorInputRef.current) {
                brandColorInputRef.current.value = foundBrand.hexCode ?? '';
            }
            dispatch(setBrandAction({ data: foundBrand }));
        }
    };

    const handleBrandColorModified = () => {
        setBrandColorModified(true);
    };

    const handleDoUpdateBrandColor = () => {
        if (selectedBrand) {
            dispatch(
                doUpdateBrandColor({
                    brandId: selectedBrand.id,
                    hexCode: parseNull(brandColorInputRef.current?.value),
                }),
            );
        }
    };

    // Render
    return (
        <Container>
            <Helmet>
                <title>{t('pages.admin.header')}</title>
            </Helmet>
            <BackdropLoader
                open={brandLoading || fileLoading || upsellLoading || serviceLoading}
                progressBar={Boolean(fileUploading === 'uploading' && fileLoading)}
            />
            <ButtonContainer onClick={logout}>{t('pages.admin.logout')}</ButtonContainer>
            {upsellSelectionError || serviceSelectionError ? (
                <ErrorAlert
                    errors={[
                        upsellSelectionError
                            ? { key: TranslationKeys.Upsell, ...upsellSelectionError }
                            : null,
                        serviceSelectionError
                            ? { key: TranslationKeys.Service, ...serviceSelectionError }
                            : null,
                    ]}
                />
            ) : (
                <>
                    <PageHeadlineContainer>
                        <PageTitle>{t('pages.admin.title')}</PageTitle>
                        <PageSubTitle>{t('pages.admin.info')}</PageSubTitle>
                    </PageHeadlineContainer>
                    <Section>
                        <Stepper>{t('pages.admin.carImageUpload.title')}</Stepper>
                        <FileUpload fileType="png" />
                    </Section>
                    <Section>
                        <Stepper>{t('pages.admin.csvUpload.title')}</Stepper>
                        <FileUpload fileType="csv" />
                    </Section>
                    <Section>
                        <Stepper>{t('pages.admin.selectBrand')}</Stepper>
                        <FormContainer>
                            <FormControl margin="dense" fullWidth>
                                <FormWrapper>
                                    <InputsLineContainer>
                                        <InputWrapper position="start">
                                            <InputLabel id="select-brand">
                                                {t('pages.admin.inputsLabels.brand')}
                                            </InputLabel>
                                            <Select
                                                labelId="select-brand"
                                                defaultValue=""
                                                label={t('pages.admin.inputsLabels.brand')}
                                                margin="dense"
                                                disabled={!allBrands}
                                                onChange={handleBrandSelection}
                                                fullWidth
                                            >
                                                {allBrands
                                                    ? allBrands.map((brand) => (
                                                          <MenuItem key={brand.id} value={brand.id}>
                                                              {brand.name}
                                                          </MenuItem>
                                                      ))
                                                    : null}
                                            </Select>
                                        </InputWrapper>
                                        <InputWrapper position="end">
                                            {selectedBrand ? (
                                                <TextField
                                                    inputRef={brandColorInputRef}
                                                    label={t('pages.admin.inputsLabels.color')}
                                                    defaultValue={selectedBrand.hexCode}
                                                    margin="dense"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleBrandColorModified}
                                                    fullWidth
                                                />
                                            ) : null}
                                        </InputWrapper>
                                    </InputsLineContainer>
                                    {selectedBrand && brandColorModified ? (
                                        <ActionButtonsContainer>
                                            <ActionButton
                                                title={t('pages.admin.actionButtons.save')}
                                                iconName="save"
                                                submitData={() => handleDoUpdateBrandColor()}
                                                disabled={false}
                                            />
                                        </ActionButtonsContainer>
                                    ) : null}
                                </FormWrapper>
                            </FormControl>
                        </FormContainer>
                        {selectedBrand ? (
                            <Section>
                                <Stepper>{t('pages.admin.detailsOfService')}</Stepper>
                                <UpsellsList selectedBrand={selectedBrand} />
                            </Section>
                        ) : null}
                    </Section>
                </>
            )}
        </Container>
    );
};

export default Admin;
