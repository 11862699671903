import noCarImage from '../assets/noCarImage.png';
import noCarSmallImage from '../assets/noCarSmallImage.png';

export const tierToolCarStepper = [
    {
        id: 0,
        title: 'pages.tierTool.cars.carsHeader',
        showCarCard: true,
    },
    {
        id: 1,
        title: 'pages.tierTool.cars.formTitle',
    },
];

export const defaultCarImage = noCarImage;

export const smallDefaultCarImage = noCarSmallImage;
