import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

import GlobalStyle from './constants/globalStyle';

import './i18n';

// CSS reset
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/assets.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/reduce-motion.css';

ReactDOM.render(
    <HelmetProvider>
        <React.StrictMode>
            <Provider store={store}>
                <>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                    <GlobalStyle />
                </>
            </Provider>
        </React.StrictMode>
    </HelmetProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// eslint-disable-next-line @typescript-eslint/no-floating-promises -- Ignore promise return of service worker
serviceWorker.unregister();
