import React from 'react';

import { FieldContainer, FieldLabel, FieldTitle } from './styles';

interface IDisplayFieldProps {
    title: string;
    children: unknown;
}

const DisplayField: React.FC<IDisplayFieldProps> = ({ title, children }) => (
    <FieldContainer>
        <FieldTitle>{title}</FieldTitle>
        <FieldLabel>{children}</FieldLabel>
    </FieldContainer>
);

export default DisplayField;
