import React from 'react';

import {
    BrandHeaderContainer,
    BrandHeaderInfo,
    BrandHeaderService,
    Container,
    LogoContainer,
} from './styles';

import S3Image from '../S3Image/S3Image';

interface IProps {
    brand: string;
    title: string;
    info: string;
}

const SubHeader: React.FC<IProps> = (props: IProps) => (
    <Container>
        <BrandHeaderContainer>
            <LogoContainer>
                <S3Image name={props.brand.toLocaleLowerCase()} />
            </LogoContainer>

            <BrandHeaderService>{String(props.title)}</BrandHeaderService>
            <BrandHeaderInfo>{String(props.info)}</BrandHeaderInfo>
        </BrandHeaderContainer>
    </Container>
);

export default SubHeader;
