import React from 'react';

// Styles
import { Container, Filler, ProgressLabel } from './styles';

interface ProgressProps {
    progress: number | null;
}

const ProgressBar = ({ progress }: ProgressProps) => (
    <Container>
        {progress && progress >= 1 && (
            <Filler uploadProgress={progress}>
                <ProgressLabel>{`${progress}%`}</ProgressLabel>
            </Filler>
        )}
    </Container>
);

export default ProgressBar;
