import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// MUI
import {
    Button,
    FormControl,
    TextField,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
} from '@mui/material';

// Slices
import {
    fetchUpsells,
    selectUpsells,
    selectUpdatedUpsell,
    updateUpsell,
    refreshUpsellServices,
} from '../../../slices/upsellsSlice';
import {
    selectAddedService,
    doAddNewService,
    clearAddedServiceAction,
} from '../../../slices/servicesSlice';

// Assets

// Constants

// Components
import ActionButton from '../../../components/ActionButton/ActionButton';
import MarkdownEditor from '../../../components/MarkdownEditor/MarkdownEditor';

// SubComponents
import ServicesList from '../ServicesList/ServicesList';

// Helpers
import { parseNull } from '../helper';
import generateUpsellImageName from '../../../utils/generateUpsellImageName';

// Styles
import {
    UpsellName,
    FormContainer,
    FormWrapper,
    InputsLineContainer,
    InputWrapper,
    ActionButtonsContainer,
    UpsellContainer,
} from '../styles';

// Types
import { ServiceKeys } from '../types';
import type { IBrandType } from '../../../types/brands';
import type { UpsellId, ModifiedUpsellsState, INewServiceDialogStateProps } from '../types';

interface IUpsellsListProps {
    selectedBrand: IBrandType;
}

const UpsellList: React.FC<IUpsellsListProps> = ({ selectedBrand }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // States
    const upsells = useSelector(selectUpsells);
    const updatedUpsell = useSelector(selectUpdatedUpsell);
    const addedService = useSelector(selectAddedService);

    const [modifiedUpsells, setModifiedUpsells] = useState<ModifiedUpsellsState>({});
    const [updatingUpsellId, setUpdatingUpsellId] = useState<UpsellId | null>(null);
    const [newServiceDialogState, setNewServiceDialogState] = useState<INewServiceDialogStateProps>(
        { isOpen: false, upsellId: null },
    );
    const [addingNewService, setAddingNewService] = useState(false);

    // Refs
    const upsellsLegals = useRef<Record<number, string | null>>({});
    const contractDisplayPriority = useRef<Record<number, null | number | null>>({});

    const newServiceRefs = useRef({
        name: t('pages.admin.actions.addingService.inputsDefaults.serviceName'),
        colour: t('pages.admin.actions.addingService.inputsDefaults.color'),
        displayPriority: t('pages.admin.actions.addingService.inputsDefaults.displayPriority'),
        description: t('pages.admin.actions.addingService.inputsDefaults.serviceDescription'),
    });

    // Helpers
    const completeAddingNewService = useCallback(() => {
        if (newServiceDialogState.upsellId) {
            dispatch(refreshUpsellServices({ upsellId: newServiceDialogState.upsellId }));
            dispatch(clearAddedServiceAction());
            setNewServiceDialogState({ isOpen: false, upsellId: null });
            setAddingNewService(false);
        }
    }, [newServiceDialogState.upsellId, dispatch]);

    // To set default values to newServiceRefs after creating a new service
    // Issue - ClickUp Task: 860pzmrdc
    const clearServicesRefData = () => {
        newServiceRefs.current.name = t(
            'pages.admin.actions.addingService.inputsDefaults.serviceName',
        );
        newServiceRefs.current.colour = t('pages.admin.actions.addingService.inputsDefaults.color');
        newServiceRefs.current.displayPriority = t(
            'pages.admin.actions.addingService.inputsDefaults.displayPriority',
        );
        newServiceRefs.current.description = t(
            'pages.admin.actions.addingService.inputsDefaults.serviceDescription',
        );
    };

    // Effects
    useEffect(() => {
        dispatch(fetchUpsells({ brandId: String(selectedBrand.id) }));
    }, [selectedBrand, dispatch]);

    useEffect(() => {
        if (upsells) {
            // Resetting upsells legal notice & images
            upsellsLegals.current = {};
            contractDisplayPriority.current = {};

            upsells.forEach((upsell) => {
                upsellsLegals.current[upsell.id] = upsell.legal;
                contractDisplayPriority.current[upsell.id] =
                    upsell.contractDisplayPriority !== null
                        ? Number(upsell.contractDisplayPriority)
                        : null;
            });
        }
    }, [upsells]);

    useEffect(() => {
        if (updatedUpsell && updatingUpsellId && updatedUpsell.id === updatingUpsellId) {
            setUpdatingUpsellId(null);
            setModifiedUpsells({
                ...modifiedUpsells,
                [updatedUpsell.id]: false,
            });
        }
    }, [updatedUpsell, updatingUpsellId, modifiedUpsells]);

    useEffect(() => {
        if (addedService) {
            completeAddingNewService();
        }
    }, [addedService, completeAddingNewService]);

    // Handlers
    const handleUpsellLegalModified = (id: number, value: string) => {
        upsellsLegals.current[id] = value;

        const alreadyModifying = modifiedUpsells[id];
        if (!alreadyModifying) {
            setModifiedUpsells({
                ...modifiedUpsells,
                [id]: true,
            });
        }
    };

    const handleContractDisplayPriority = (id: number, priority: number) => {
        contractDisplayPriority.current[id] = priority;

        const alreadyModifying = modifiedUpsells[id];
        if (!alreadyModifying) {
            setModifiedUpsells({
                ...modifiedUpsells,
                [id]: true,
            });
        }
    };

    const handleDoUpdateUpsell = (id: number) => {
        if (updatingUpsellId) return;
        setUpdatingUpsellId(id);
        dispatch(
            updateUpsell({
                upsellId: id,
                legal: parseNull(upsellsLegals.current[id]),
                contractDisplayPriority: contractDisplayPriority.current[id],
            }),
        );
    };

    // Manual Dialog closing handler, reset inputs & close dialog
    const handleCloseDialog = () => {
        if (addingNewService) return;
        setNewServiceDialogState({ isOpen: false, upsellId: null });
    };

    const handleOpenDialog = (upsellId: number) => {
        setNewServiceDialogState({ isOpen: true, upsellId });
    };

    const handleAddNewService = () => {
        if (addingNewService) return;
        setAddingNewService(true);

        const inputServiceName = parseNull(newServiceRefs.current.name);
        const inputServiceDisplayPriority = Number(newServiceRefs.current.displayPriority);
        const inputServiceColor = parseNull(newServiceRefs.current.colour);
        const inputServiceDescription = parseNull(newServiceRefs.current.description);

        if (newServiceDialogState.upsellId && inputServiceDisplayPriority) {
            dispatch(
                doAddNewService({
                    upsellId: newServiceDialogState.upsellId,
                    name: inputServiceName,
                    displayPriority: inputServiceDisplayPriority,
                    hexCode: inputServiceColor,
                    description: inputServiceDescription,
                }),
            );
            clearServicesRefData();
        } else {
            setAddingNewService(false);
        }
    };

    const handleNewServiceInputModified = (key: ServiceKeys, value: string) => {
        const ref = newServiceRefs.current;
        switch (key) {
            case ServiceKeys.NAME:
                ref.name = value;
                break;
            case ServiceKeys.COLOUR:
                if (ref.colour) {
                    ref.colour = value;
                }
                break;
            case ServiceKeys.DISPLAY_PRIORITY:
                ref.displayPriority = value;
                break;
            case ServiceKeys.DESCRIPTION:
                ref.description = value;
                break;
            default:
                throw new Error('Invalid key');
        }
    };

    // Render
    return (
        <div>
            {upsells ? (
                upsells.map((upsell) => (
                    <UpsellContainer key={upsell.id}>
                        <UpsellName>
                            {t('pages.admin.upsellTitle', {
                                upsellLevel: upsell.level ?? '0',
                                upsellName: upsell.name,
                            })}
                        </UpsellName>
                        <FormContainer>
                            <FormControl margin="dense" fullWidth>
                                <FormWrapper>
                                    <InputsLineContainer>
                                        <InputWrapper size={2} position="start">
                                            <MarkdownEditor
                                                label={t('pages.admin.inputsLabels.legalNotice')}
                                                defaultValue={upsell.legal}
                                                onChange={(value: string) =>
                                                    handleUpsellLegalModified(upsell.id, value)
                                                }
                                            />
                                        </InputWrapper>
                                        <InputWrapper size={1} position="end">
                                            <TextField
                                                label={t('pages.admin.inputsLabels.upsellImage')}
                                                value={generateUpsellImageName({
                                                    brand: selectedBrand.name,
                                                    upsellName: upsell.name,
                                                })}
                                                margin="dense"
                                                disabled
                                                fullWidth
                                            />
                                            <TextField
                                                label={t('pages.admin.contractDropdownPriority')}
                                                defaultValue={
                                                    upsell.contractDisplayPriority === null ||
                                                    !upsell.contractDisplayPriority
                                                        ? ''
                                                        : Number(upsell.contractDisplayPriority)
                                                }
                                                margin="dense"
                                                onChange={(event) =>
                                                    handleContractDisplayPriority(
                                                        upsell.id,
                                                        Number(event.currentTarget.value),
                                                    )
                                                }
                                                fullWidth
                                            />
                                        </InputWrapper>
                                    </InputsLineContainer>
                                </FormWrapper>
                                <ActionButtonsContainer>
                                    {modifiedUpsells[upsell.id] ? (
                                        <ActionButton
                                            title={t('pages.admin.actionButtons.save')}
                                            iconName="save"
                                            submitData={() => handleDoUpdateUpsell(upsell.id)}
                                            disabled={Boolean(updatingUpsellId)}
                                        />
                                    ) : null}
                                </ActionButtonsContainer>
                            </FormControl>
                        </FormContainer>
                        <ServicesList upsell={upsell} />
                        <ActionButtonsContainer>
                            <ActionButton
                                title={t('pages.admin.actionButtons.add')}
                                iconName="add"
                                submitData={() => handleOpenDialog(upsell.id)}
                                disabled={addingNewService}
                            />
                        </ActionButtonsContainer>
                    </UpsellContainer>
                ))
            ) : (
                <div />
            )}

            <Dialog
                open={newServiceDialogState.isOpen}
                onClose={() => handleCloseDialog()}
                aria-labelledby="confirm-dialog"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="confirm-dialog">{t('pages.admin.newServiceTitle')}</DialogTitle>
                <DialogContent>
                    {t('pages.admin.newServiceText')}
                    <br />
                    <FormContainer>
                        <FormControl margin="dense" fullWidth>
                            <FormWrapper>
                                <InputsLineContainer>
                                    <InputWrapper size={2} position="start">
                                        <MarkdownEditor
                                            label={t(
                                                'pages.admin.actions.addingService.inputsLabels.serviceName',
                                            )}
                                            defaultValue={t(
                                                'pages.admin.actions.addingService.inputsDefaults.serviceName',
                                            )}
                                            onChange={(value: string) =>
                                                handleNewServiceInputModified(
                                                    ServiceKeys.NAME,
                                                    value,
                                                )
                                            }
                                        />
                                    </InputWrapper>
                                    <InputWrapper size={1} position="middle">
                                        <TextField
                                            label={t(
                                                'pages.admin.actions.addingService.inputsLabels.displayPriority',
                                            )}
                                            type="number"
                                            defaultValue={t(
                                                'pages.admin.actions.addingService.inputsDefaults.displayPriority',
                                            )}
                                            margin="dense"
                                            onChange={(e) =>
                                                handleNewServiceInputModified(
                                                    ServiceKeys.DISPLAY_PRIORITY,
                                                    e.target.value,
                                                )
                                            }
                                            fullWidth
                                        />
                                    </InputWrapper>
                                    <InputWrapper size={1} position="end">
                                        <TextField
                                            label={t(
                                                'pages.admin.actions.addingService.inputsLabels.color',
                                            )}
                                            defaultValue={t(
                                                'pages.admin.actions.addingService.inputsDefaults.color',
                                            )}
                                            margin="dense"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) =>
                                                handleNewServiceInputModified(
                                                    ServiceKeys.COLOUR,
                                                    e.target.value,
                                                )
                                            }
                                            fullWidth
                                        />
                                    </InputWrapper>
                                </InputsLineContainer>
                                <InputsLineContainer>
                                    <InputWrapper>
                                        <MarkdownEditor
                                            label={t(
                                                'pages.admin.actions.addingService.inputsLabels.serviceDescription',
                                            )}
                                            defaultValue={t(
                                                'pages.admin.actions.addingService.inputsDefaults.serviceDescription',
                                            )}
                                            onChange={(value: string) =>
                                                handleNewServiceInputModified(
                                                    ServiceKeys.DESCRIPTION,
                                                    value,
                                                )
                                            }
                                        />
                                    </InputWrapper>
                                </InputsLineContainer>
                            </FormWrapper>
                        </FormControl>
                    </FormContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => handleCloseDialog()}
                        disabled={addingNewService}
                    >
                        {t('pages.admin.actionButtons.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleAddNewService()}
                        disabled={addingNewService}
                    >
                        {t('pages.admin.actionButtons.add')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UpsellList;
