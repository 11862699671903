import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import DisplayField from '../../../../components/DisplayField/DisplayField';

// Assets
import {
    ContractIcon,
    DurationIcon,
    InitialDateIcon,
    MileageIcon,
    SubscriptionDateIcon,
} from '../../../../assets/icons';

// Utils
import {
    renderSelectedDuration,
    renderSelectedMileage,
} from '../../../../utils/formatDropdownValue';
import { formatFrenchDate } from '../../../../utils/formatFrenchDate';

// Styles
import { FormContainer } from '../styles';
// Types
import type { IUpsellType } from '../../../../types/upsells';

export interface ContractDataProps {
    duration: string | null;
    mileage: string | null;
    selectedUpsell: IUpsellType;
    initialDate: string | null;
    subscriptionDate: string | null;
    translationBase: string;
}

const ContractData: React.FC<ContractDataProps> = (props: ContractDataProps) => {
    const { duration, mileage, selectedUpsell, initialDate, subscriptionDate, translationBase } =
        props;
    const { t } = useTranslation();

    return (
        <FormContainer>
            <DisplayField title={String(selectedUpsell.name)}>
                <ContractIcon />
                {t(`${translationBase}.contractLabel`)}
            </DisplayField>

            {duration && (
                <DisplayField title={renderSelectedDuration(duration, duration, t)}>
                    <DurationIcon />
                    {t(`${translationBase}.durationLabel`)}
                </DisplayField>
            )}
            {mileage && duration && (
                <DisplayField title={renderSelectedMileage(mileage, duration, t)}>
                    <MileageIcon />
                    {t(`${translationBase}.mileageLabel`)}
                </DisplayField>
            )}
            {initialDate && (
                <DisplayField title={formatFrenchDate(initialDate)}>
                    <InitialDateIcon />
                    {t(`${translationBase}.initialDateLabel`)}
                </DisplayField>
            )}

            {subscriptionDate && (
                <DisplayField title={formatFrenchDate(subscriptionDate)}>
                    <SubscriptionDateIcon />
                    {t(`${translationBase}.subscriptionDateLabel`)}
                </DisplayField>
            )}
        </FormContainer>
    );
};
export default ContractData;
