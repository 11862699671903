import moment from 'moment';

export const getContractPrice = (
    initialDate: string,
    subscriptionDate: string,
    subscriptionPrice: string,
    duration: string,
) => {
    // Get the difference of InitialDate and SubscriptionDate in months
    const differenceInMonths =
        // If the difference is less than 1 (0.9), set to 0, else (1.6) round up
        Number(moment(subscriptionDate).diff(moment(initialDate), 'months', true)) < 1
            ? 0
            : Math.round(moment(subscriptionDate).diff(moment(initialDate), 'months', true));

    // Calculate the correct price
    const calculatedPrice = (
        (Number(subscriptionPrice) * Number(duration)) /
        (Number(duration) - differenceInMonths)
    ).toFixed(2);

    return calculatedPrice;
};
