import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// Slices
import {
    selectCarLoading,
    fetchLissageContracts,
    fetchCars,
    selectAllCars,
    selectSelectedCar,
    selectSelectedFullCar,
    updateSelectedCar,
} from '../../../slices/carsSlice';
import {
    fetchBrand,
    selectBrand,
    selectBrandLoading,
    selectBrandErrors,
} from '../../../slices/brandSlice';
import {
    fetchLissageUpsells,
    selectContractSelections,
    clearContractSelection,
} from '../../../slices/contractsSlice';
import { selectSelectedUpsell, updateSelectedUpsell } from '../../../slices/upsellsSlice';

// Components
import Page from '../../../components/Page/Page';
import SubHeader from '../../../components/SubHeader/SubHeader';
import Stepper from '../../../components/Stepper/Stepper';
import CarsContainer from '../../../components/CarsContainer/CarsContainer';
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader';
import ErrorAlert from '../../../components/ErrorAlert/ErrorAlert';
import LissageForm from './form/Form';

// Types
import type { ICarsType } from '../../../types/cars';
import { TranslationKeys } from '../../../types/errors/index';
import { tierToolCarStepper } from '../../../constants/cars';

// Styles
import { Container, StepperContainer } from './styles';

// Utils
import {
    formComplete,
    fetchUpsellsParams,
    fetchContractsParams,
    lissageCarsPageComplete,
} from '../../../utils/contract-helper';

const translationBase = 'pages.tierTool.cars';

const Cars: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { brand: brandName } = useParams();

    // Store
    const brandLoading = useSelector(selectBrandLoading);
    const carLoading = useSelector(selectCarLoading);
    const formSelections = useSelector(selectContractSelections);
    const selectedCar = useSelector(selectSelectedFullCar);
    const selectedBrand = useSelector(selectBrand);
    const availableCars = useSelector(selectAllCars);
    const storedCar = useSelector(selectSelectedCar);
    const upsell = useSelector(selectSelectedUpsell);
    const brandSelectionError = useSelector(selectBrandErrors);

    // States
    const [activeStep, setActiveStep] = useState(0);
    const [carsSelectionEdit, setCarsSelectionEdit] = useState(false);

    useEffect(() => {
        if (!selectedBrand && brandName) {
            dispatch(fetchBrand({ brandName }));
        } else if (selectedBrand && availableCars.length === 0) {
            dispatch(fetchCars({ brandId: selectedBrand.id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- No need for dispatch etc
    }, [availableCars.length, brandName, selectedBrand]);

    useEffect(() => {
        if (selectedCar && !carsSelectionEdit) {
            setActiveStep(1);
        }
        if (!selectedCar && !carsSelectionEdit) {
            setActiveStep(0);
        }
    }, [selectedCar, carsSelectionEdit]);

    useEffect(() => {
        if (
            selectedBrand?.id &&
            selectedCar?.id &&
            formSelections &&
            formComplete(formSelections)
        ) {
            dispatch(
                fetchLissageUpsells(fetchUpsellsParams(selectedBrand, selectedCar, formSelections)),
            );
            dispatch(fetchLissageContracts(fetchContractsParams(selectedCar, formSelections)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- No need for dispatch etc
    }, [selectedBrand?.id, selectedCar?.id]);

    const handleSelectCar = (car: ICarsType) => {
        setCarsSelectionEdit(false);
        dispatch(updateSelectedCar(car));
    };

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setCarsSelectionEdit(true);
        dispatch(clearContractSelection()); // Reset dropdowns to default after user clicks on edit button
        dispatch(updateSelectedUpsell(null));
    };

    return (
        <>
            <Helmet>
                <title>{t(`${translationBase}.title`)}</title>
            </Helmet>
            <Page>
                <Container>
                    <BackdropLoader open={brandLoading || carLoading} />
                    {brandSelectionError && (
                        <ErrorAlert
                            errors={[{ key: TranslationKeys.Brand, ...brandSelectionError }]}
                        />
                    )}
                    {selectedBrand && brandName && (
                        <>
                            <SubHeader
                                brand={brandName}
                                title={t(`${translationBase}.title`)}
                                info={t(`${translationBase}.info`)}
                            />
                            <StepperContainer>
                                <Stepper
                                    selectedCar={selectedCar}
                                    activeStep={activeStep}
                                    stepConstants={tierToolCarStepper}
                                    edit={carsSelectionEdit}
                                    handleBackStep={handleBackStep}
                                    displayCheckMark={
                                        !carsSelectionEdit &&
                                        lissageCarsPageComplete(formSelections, selectedCar, upsell)
                                    }
                                >
                                    {activeStep === 1 && selectedCar ? (
                                        <LissageForm formSelections={formSelections} />
                                    ) : (
                                        <CarsContainer
                                            availableCars={availableCars}
                                            onSelect={(selected2Car) =>
                                                handleSelectCar(selected2Car)
                                            }
                                            selectedCarId={selectedCar?.id ?? null}
                                        />
                                    )}
                                </Stepper>
                            </StepperContainer>
                        </>
                    )}
                </Container>
            </Page>
        </>
    );
};

export default Cars;
