import { normalizeName } from './s3-images-helper';

interface IProps {
    brand: string;
    upsellName: string;
}

/**
 * Generates the image name for the given brand and upsell.
 * @param {string} brand Brand Name
 * @param {string} upsellName Upsell name
 * @returns image name (Ex.: 'peugeot-upsell-1')
 */
const generateUpsellImageName = ({ brand, upsellName }: IProps) => {
    const lowerCaseBrand = brand.toLocaleLowerCase();
    const lowerCaseUpsellName = normalizeName(upsellName);
    return `${lowerCaseBrand}-${lowerCaseUpsellName}`;
};

export default generateUpsellImageName;
