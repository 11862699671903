import styled from 'styled-components';

// Constants
import { colours } from '../../../../constants/colours';
import { minSizes, maxSizes } from '../../../../constants/sizes';

// Shared Styles
import { StyledH3 } from '../styles';

// Types
import type { IPriceTagProps } from '../types';

export const BundleWrapper = styled.div`
    position: relative;
    width: 33%;
    height: 100%;
    display: inline-block;

    @media ${maxSizes.small} {
        width: 100%;
        display: block;

        border-bottom: 1px solid ${colours.lightGrey};
    }

    @media ${minSizes.large} {
        width: 30%;
    }
`;

export const Bundle = styled.div`
    position: relative;
    padding: 0 0.25rem;
`;

export const ImageContainer = styled.div`
    width: 100%;
    min-height: 4.75rem;
    margin-bottom: 2rem;
    text-align: center;

    &.car img {
        width: 100%;
        max-width: 24.5rem;
        height: 100%;
        max-height: 13rem;
    }

    &.cube img {
        max-width: 24.4rem;
        height: 100%;
        max-height: 7.5rem;
    }
`;

export const NameContainer = styled.div`
    min-height: 4rem;
    margin-bottom: 2rem;
    text-align: center;
`;

export const Name = styled(StyledH3)`
    color: ${colours.black};
`;

export const ChevronWrapper = styled.div`
    width: 2.5rem;
    margin-top: -3rem;
    display: inline-block;
    float: right;
    position: relative;
    left: calc(1rem + 2vw);

    @media ${maxSizes.small} {
        display: none;
    }
`;

export const PriceTagWrapper = styled.div`
    margin-bottom: 3rem;
`;

export const PriceTag = styled.div<IPriceTagProps>`
    width: fit-content;
    margin: 0 auto;
    border-radius: 0.25rem;
    padding: 0.25rem 1.656rem;
    background-color: ${({ bgColorHexCode }: IPriceTagProps) => bgColorHexCode};
`;

export const Price = styled(StyledH3)`
    color: ${colours.white};
`;

export const BundleServicesContainer = styled.div`
    margin-bottom: 2.5rem;
`;

export const DetailsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
    column-gap: 1rem;
`;

export const NoContractsMessage = styled.span`
    width: fit-content;
    margin: 0 auto;
    background-color: ${colours.white};
    color: ${({ bgColorHexCode }: IPriceTagProps) => bgColorHexCode};

    font-size: 1rem;
    font-weight: 700;
    line-height: 133%;

    text-align: center;
    display: flex;
`;
