const bucket = process.env.REACT_APP_S3_BUCKET_NAME;
export const s3ImagesURL = () => `https://${bucket}.s3.amazonaws.com/`;

export const normalizeName = (name: string) =>
    name
        .normalize('NFD')
        .replaceAll(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replaceAll('&', 'et')
        .replaceAll(' ', '-');
