import React from 'react';
import { useLocation, Navigate, Routes, Route } from 'react-router-dom';

import UpsellCarsPage from './pages/upsell/cars/Cars';
import UpsellContractsPage from './pages/upsell/contracts/Contracts';
import LoginPage from './pages/login/Login';
import AdminPage from './pages/admin/Admin';
import LissagePage from './pages/tierTool/cars/Cars';
import LissageContractPage from './pages/tierTool/contract/Contract';

import { authenticationService } from './utils/auth-helper';

import { APP_ROUTES } from './constants/app.routes';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    if (!authenticationService.currentUserValue) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
};

const LocalRoutes: React.FunctionComponent = () => (
    <Routes>
        <Route path={APP_ROUTES.LISSAGE} element={<LissagePage />} />
        <Route path={APP_ROUTES.LISSAGE_CONTRACT} element={<LissageContractPage />} />
        <Route path={APP_ROUTES.UPSELL_CARS} element={<UpsellCarsPage />} />
        <Route path={APP_ROUTES.UPSELL_CONTRACTS} element={<UpsellContractsPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
            path="/admin"
            element={
                <RequireAuth>
                    <AdminPage />
                </RequireAuth>
            }
        />
    </Routes>
);

export default LocalRoutes;
