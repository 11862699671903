import styled from 'styled-components';

// Constants
import { colours } from '../../../../constants/colours';

export const LegalNotice = styled.span`
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${colours.mediumGrey};
`;
