import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Slices
import {
    fetchCar,
    selectCarLoading,
    selectSelectedFullCar,
    selectCarErrors,
    clearSelectedData,
} from '../../../slices/carsSlice';
import {
    fetchBrand,
    selectBrand,
    selectBrandLoading,
    selectBrandErrors,
} from '../../../slices/brandSlice';
import {
    selectContractsLoading,
    selectContract,
    fetchContractByParams,
    updateSelectedContract,
    updateContractsBundle,
    selectContractSelections,
    updateSelections,
    clearContractSelection,
} from '../../../slices/contractsSlice';
import {
    selectUpsellsLoading,
    fetchLissageSingleUpsell,
    selectSelectedUpsell,
    selectUpsellsErrors,
    clearUpsellData,
} from '../../../slices/upsellsSlice';

// Components
import Page from '../../../components/Page/Page';
import SubHeader from '../../../components/SubHeader/SubHeader';
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader';
import ErrorAlert from '../../../components/ErrorAlert/ErrorAlert';
import ContractData from './sections/ContractData';
import PriceDisplay from './sections/PriceDisplay';
import CarAndCubes from './sections/CarAndCubes';
import ContractDetails from './sections/ContractDetails';

// Helpers & Constants
import { APP_ROUTES } from '../../../constants/app.routes';
import { parseYear } from '../../../utils/parseTime';

// Assets
import { ArrowBack } from '../../../assets/icons';

// Styles
import {
    Container,
    DetailsContainer,
    CarName,
    DetailsGroup,
    ContractIncludesText,
    BackButtonWrapper,
    PriceInfoText,
    LegalNotice,
} from './styles';
import type { IServiceType } from '../../../types/services';
import { ContractFields } from '../../../types/contracts';

// Types
import { TranslationKeys } from '../../../types/errors/index';

const translationBase = 'pages.tierTool.contract';

const Contract: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { brand, carId, initialDate, subscriptionDate, duration, mileage, upsellId, name } =
        useParams();

    // Store
    const selectedBrand = useSelector(selectBrand);
    const selectedFullCar = useSelector(selectSelectedFullCar);
    const selectedUpsell = useSelector(selectSelectedUpsell);
    const formSelections = useSelector(selectContractSelections);

    const contract = useSelector(selectContract);

    const brandLoading = useSelector(selectBrandLoading);
    const carLoading = useSelector(selectCarLoading);
    const contractLoading = useSelector(selectContractsLoading);
    const upsellLoading = useSelector(selectUpsellsLoading);

    const brandSelectionError = useSelector(selectBrandErrors);
    const carSelectionError = useSelector(selectCarErrors);
    const upsellSelectionError = useSelector(selectUpsellsErrors);

    // State
    const [services, setServices] = useState<IServiceType[]>([]);

    // In case of page refresh or page bookmarked

    useEffect(() => {
        if (!formSelections?.duration && duration) {
            dispatch(updateSelections({ field: ContractFields.duration, value: duration }));
        }

        if (!formSelections?.mileage && mileage) {
            dispatch(updateSelections({ field: ContractFields.mileage, value: mileage }));
        }

        if (!formSelections?.initialDate && initialDate) {
            dispatch(updateSelections({ field: ContractFields.initialDate, value: initialDate }));
        }
        if (!formSelections?.name && name) {
            dispatch(
                updateSelections({
                    field: ContractFields.name,
                    value: name.replaceAll('_', ' '),
                }),
            );
        }
        if (!formSelections?.subscriptionDate && subscriptionDate) {
            dispatch(
                updateSelections({
                    field: ContractFields.subscriptionDate,
                    value: subscriptionDate,
                }),
            );
        }
    }, [
        brand,
        carId,
        upsellId,
        dispatch,
        duration,
        initialDate,
        mileage,
        subscriptionDate,
        formSelections?.duration,
        formSelections?.mileage,
        formSelections?.initialDate,
        formSelections?.subscriptionDate,
        name,
        formSelections?.name,
    ]);

    useEffect(() => {
        // Set initial services to expanded false
        if (selectedUpsell?.relatedServices) {
            const updatedServices = selectedUpsell.relatedServices.map((service) => {
                if (service.name && service.description) {
                    return { ...service, expanded: false };
                } else {
                    return service;
                }
            });
            setServices(updatedServices);
        }
    }, [selectedUpsell?.relatedServices]);

    useEffect(() => {
        if (!contract && name && carId && duration && mileage && selectedFullCar?.shortName) {
            const contractName = name.replaceAll('_', ' ');
            dispatch(
                fetchContractByParams({
                    carId,
                    contractName,
                    duration,
                    mileage,
                    carName: selectedFullCar.shortName,
                }),
            );
        }
    }, [carId, dispatch, duration, mileage, contract, name, selectedFullCar?.shortName]);

    useEffect(() => {
        if (!selectedBrand && brand && !brandSelectionError) {
            dispatch(fetchBrand({ brandName: brand }));
        }

        if (!selectedFullCar && carId && !carSelectionError) {
            dispatch(fetchCar(carId));
        }
        if (upsellId && !selectedUpsell?.relatedServices && !upsellSelectionError) {
            dispatch(fetchLissageSingleUpsell({ upsellId }));
        }
    }, [
        brand,
        carId,
        upsellId,
        dispatch,
        selectedBrand,
        selectedUpsell,
        selectedFullCar,
        brandSelectionError,
        carSelectionError,
        upsellSelectionError,
    ]);

    const handleRecalculate = () => {
        dispatch(updateSelectedContract(null));
        dispatch(updateContractsBundle(null));

        navigate(
            generatePath(APP_ROUTES.LISSAGE, {
                brand: selectedBrand?.name.toLowerCase() ?? brand?.toLocaleLowerCase(),
            }),
        );
    };

    const handleNavigateBack = useCallback(() => {
        dispatch(clearSelectedData());
        dispatch(clearContractSelection());
        dispatch(clearUpsellData());

        navigate(
            generatePath(APP_ROUTES.LISSAGE, {
                brand: selectedBrand?.name.toLowerCase() ?? brand?.toLocaleLowerCase(),
            }),
        );
    }, [brand, dispatch, navigate, selectedBrand?.name]);

    useEffect(() => {
        // Flush stored data when using browser back button
        window.onpopstate = () => {
            handleNavigateBack();
        };
    }, [handleNavigateBack]);

    return (
        <>
            <Helmet>
                <title>{t(`${translationBase}.title`)}</title>
            </Helmet>
            <Page>
                <Container>
                    <BackdropLoader
                        open={brandLoading || carLoading || contractLoading || upsellLoading}
                    />

                    <BackButtonWrapper onClick={() => handleNavigateBack()}>
                        <ArrowBack />
                        <span>{t('pages.upsell.contracts.backButton')}</span>
                    </BackButtonWrapper>
                    {(brandSelectionError || carSelectionError || upsellSelectionError) && (
                        <ErrorAlert
                            errors={[
                                brandSelectionError
                                    ? { key: TranslationKeys.Brand, ...brandSelectionError }
                                    : null,
                                carSelectionError
                                    ? { key: TranslationKeys.Car, ...carSelectionError }
                                    : null,
                                upsellSelectionError
                                    ? { key: TranslationKeys.Upsell, ...upsellSelectionError }
                                    : null,
                            ]}
                        />
                    )}
                    {selectedBrand && selectedFullCar && selectedUpsell && (
                        <>
                            <SubHeader
                                brand={String(brand).toLowerCase()}
                                title={t(`${translationBase}.title`)}
                                info={t(`${translationBase}.info`)}
                            />

                            <DetailsContainer>
                                <CarAndCubes
                                    carImage={selectedFullCar.carImage ?? ''}
                                    brand={selectedBrand.name}
                                    upsellName={selectedUpsell.name}
                                />
                                <DetailsGroup>
                                    <CarName>{selectedFullCar.fullName}</CarName>
                                    <PriceDisplay
                                        duration={duration ?? null}
                                        initialDate={initialDate ?? null}
                                        subscriptionDate={subscriptionDate ?? null}
                                        subscriptionPrice={contract?.subscriptionPrice ?? null}
                                        hexCode={selectedBrand.hexCode ?? null}
                                    />
                                    <ContractData
                                        selectedUpsell={selectedUpsell}
                                        mileage={mileage ?? null}
                                        duration={duration ?? null}
                                        initialDate={initialDate ?? null}
                                        subscriptionDate={subscriptionDate ?? null}
                                        translationBase={translationBase}
                                    />

                                    <ContractIncludesText>
                                        {t(`${translationBase}.contractIncludesText`)}
                                    </ContractIncludesText>

                                    {services.length > 0 && (
                                        <ContractDetails
                                            translationBase={translationBase}
                                            initialServices={services}
                                            handleRecalculate={handleRecalculate}
                                        />
                                    )}
                                    <PriceInfoText>
                                        {t(`${translationBase}.priceInformation`)}
                                    </PriceInfoText>

                                    <LegalNotice>
                                        {`
                                        ${t('pages.upsell.contracts.legal')}
                                        ${t('pages.upsell.contracts.legalNoticeSeparator')}
                                        ${selectedUpsell.name}
                                        - ${parseYear(Number(duration))} ${t('global.years')}
                                        (${Number(duration)} ${t('global.month')})
                                        - ${Number(mileage)}${t('global.km')} ${
                                            selectedUpsell.legal ? `, ${selectedUpsell.legal}` : ''
                                        }
                                    `}
                                    </LegalNotice>
                                </DetailsGroup>
                            </DetailsContainer>
                        </>
                    )}
                </Container>
            </Page>
        </>
    );
};
export default Contract;
