import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Slices
import { updateSelections, clearContractSelection } from '../../../../../slices/contractsSlice';

// Components
import SelectDate from '../../../../../components/SelectDate/SelectDate';

// Types
import { ContractFields } from '../../../../../types/contracts';

import { valueAsDate } from '../../../../../utils/formatFrenchDate';

export interface FormProps {
    initialDate: string | null;
}

const SelectWarrantyStartDate: React.FC<FormProps> = (props: FormProps) => {
    const { initialDate } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSelectInitialDate = (date: string) => {
        dispatch(clearContractSelection());
        dispatch(updateSelections({ field: ContractFields.initialDate, value: String(date) }));
    };

    return (
        <SelectDate
            id="initialDate"
            selectLabel={t(`pages.tierTool.cars.initialDateLabel`)}
            value={valueAsDate(initialDate)}
            setValue={(date) => handleSelectInitialDate(String(date))}
        />
    );
};

export default SelectWarrantyStartDate;
