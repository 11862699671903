import styled from 'styled-components';

const labelColor = 'rgba(0, 0, 0, 0.6)';
const borderColor = 'rgba(0, 0, 0, 0.23)';
const border = `1px solid ${borderColor}`;

export const EditorContainer = styled.div`
    position: relative;
    margin: 0.3rem 0 0.5rem;
    padding: 0.75rem 0.125rem;

    .editor-wrapper {
        z-index: 1;
        position: relative;
    }

    .editor {
        padding: 0 1rem;
        cursor: text;
    }

    .rdw-editor-toolbar {
        border-top: none;
        border-left: ${border};
        border-right: ${border};
        border-bottom: ${border};
    }
`;

export const StyledFieldset = styled.fieldset`
    z-index: 0;
    position: absolute;

    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;

    border: ${border};
    border-radius: 4px;
`;

export const StyledLegend = styled.legend`
    font-weight: 400;
    font-size: 0.75em;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: ${labelColor};
`;
