/**
 * Formats into price. Example: "1234567.89" -> "1 234 567,89"
 * @param {string | number} n
 * @returns formated string
 */
const formatToPrice = (n: string | number) =>
    Number(n)
        .toFixed(2)
        .replace(/\./, ',') // <- Decimal separator (radix)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

export default formatToPrice;
