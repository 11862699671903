import type { FormEvent } from 'react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ErrorMessages, ErrorCodes } from '../../types/errors/index';

import type { RootState } from '../../store';

import {
    Container,
    FormContainer,
    Title,
    FormLogin,
    PaperContainer,
    ButtonContainer,
    TextfieldContainer,
    ErrorText,
} from './styles';
import { loginUser, selectUser, setErrorsAction, whoamiRequest } from '../../slices/authSlice';

const Login: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const authError = useSelector((state: RootState) => state.auth.errors);
    const user = useSelector(selectUser);

    useEffect(() => {
        if (user) {
            navigate('/admin', {});
        } else {
            const API_TOKEN = localStorage.getItem('api_token');
            if (API_TOKEN) {
                dispatch(whoamiRequest());
            }
        }
    }, [dispatch, navigate, user]);

    const handleChangeEmail = (value: string) => {
        if (authError) {
            dispatch(setErrorsAction(null));
        }
        setEmail(value);
    };

    const handleChangePass = (value: string) => {
        if (authError) {
            dispatch(setErrorsAction(null));
        }
        setPassword(value);
    };

    const canFormSubmit = () => email.trim() && password.trim();

    const authHandler = (e: FormEvent) => {
        e.preventDefault();
        submitForm();
    };

    const submitForm = () => {
        if (canFormSubmit()) {
            dispatch(loginUser({ email, password }));
        }
    };

    return (
        <Container id="logout">
            <Helmet>
                <title>{t('pages.login.header')}</title>
            </Helmet>
            <PaperContainer elevation={2} id="login">
                <FormLogin onSubmit={authHandler}>
                    <Title>{t('pages.login.header')}</Title>
                    <FormContainer>
                        {/* Note Textfield default styling was overwritten with !important */}
                        <TextfieldContainer
                            data-testid="email-input"
                            name="email"
                            type="email"
                            label={t('pages.login.email')}
                            variant="outlined"
                            onChange={(e: React.FormEvent) =>
                                handleChangeEmail((e.target as HTMLInputElement).value)
                            }
                            value={email}
                        />
                        <TextfieldContainer
                            data-testid="password-input"
                            name="password"
                            type="password"
                            label={t('pages.login.password')}
                            variant="outlined"
                            onChange={(e: React.FormEvent) =>
                                handleChangePass((e.target as HTMLInputElement).value)
                            }
                            value={password}
                        />
                        {authError && (
                            <ErrorText>
                                {authError.errorCode &&
                                authError.errorCode === ErrorCodes.InvalidCredentials
                                    ? t(`pages.login.loginUnauthorized`)
                                    : t(`pages.login.loginFail`)}
                            </ErrorText>
                        )}
                        <ButtonContainer onClick={authHandler}>
                            {t('pages.login.submit')}
                        </ButtonContainer>
                    </FormContainer>
                </FormLogin>
            </PaperContainer>
        </Container>
    );
};

export default Login;
