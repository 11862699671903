// Types
import type { IUpsellType } from '../../../types/upsells/index';

// Mapping all services with needed properties
export const getMappedServicesObject = (
    upsell: IUpsellType,
    upsellIndex: number,
    upsellArray: IUpsellType[],
) => {
    if (!upsell.relatedServices) return [];

    const mappedServices = [...upsell.relatedServices]
        .sort((a, b) => a.displayPriority - b.displayPriority)
        .map((service) => {
            let serviceSeenBefore = false;
            if (service.name) {
                for (let i = 0; i < upsellIndex; i++) {
                    const upsellItem = upsellArray[i];
                    if (upsellItem.relatedServices) {
                        for (let j = 0; j < upsellItem.relatedServices.length; j++) {
                            const itemService = upsellItem.relatedServices[j];
                            if (itemService.name === service.name) {
                                serviceSeenBefore = true;
                            }
                        }
                    } else {
                        serviceSeenBefore = false;
                    }
                }
            }

            const defaultExpanded = !service.name ? true : !serviceSeenBefore;

            return {
                ...service,
                defaultExpanded,
            };
        });

    return mappedServices;
};
