import styled, { css } from 'styled-components';

// Constants
import { colours } from '../../../constants/colours';

// Types
import type { IButtonProps, IStepperNavigationBarProps, ICardProps } from './types';

export const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;

    #stepper {
        display: flex;
        width: 100%;
        max-width: 1224px;
        border: none;
        margin: auto;
        margin-bottom: 48px;

        .stepCompletedIcon {
            color: ${colours.success};
            font-size: 24;
        }

        .stepArrowIcon {
            color: ${colours.blue};
            font-size: 24;
        }

        .MuiStepConnector-line {
            border: none;
        }

        /* MUI Class for the select component */
        .MuiInputBase-root {
            height: 40px;
            border: none;

            /* Removing the border before selecting an option */
            &:before {
                border: none;
            }

            /* Removing the border after selecting an option */
            &:after {
                border: none;
            }
        }

        .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
            color: ${colours.blue};
        }

        .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
            color: ${colours.blue};
        }

        .css-117w1su-MuiStepIcon-text {
            font-weight: 700;
            font-size: 16px;
        }
    }

    #stepperLabel {
        display: flex;
    }

    #stepperLabelContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        p {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        svg {
            margin-left: 1rem;
            height: 24px;
        }
    }

    #stepperContent {
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: 0;
        border: none;
        width: 100%;
    }

    @media (max-width: 1260px) {
        #stepper {
            padding: 0 24px;
        }
    }

    @media (max-width: 810px) {
        #stepper {
            margin-bottom: 60px;
        }
    }
`;
export const StepContainer = styled.div`
    margin: 0 48px;
    width: 100%;
`;

export const FormContainer = styled.div`
    display: inline-block;
    max-width: 1224px;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0;
`;

export const StepText = styled.p``;

export const StepperNavigationBar = styled.div<IStepperNavigationBarProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 1rem;

    color: ${colours.mediumGrey};
    font-weight: 700;
    font-size: 16px;

    ${({ isActive }) =>
        isActive
            ? `
        color: ${colours.blue};
    `
            : ``}
`;

export const StepperArrowsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    cursor: pointer;

    // Modify Label left of arrow
    p {
        display: flex;
        height: 24px;
        border: none;
        margin-right: 1rem;
        font-size: 16px;
    }

    // Arrow icons
    svg {
        cursor: pointer;
    }
`;

export const Separator = styled.div`
    display: flex;
    width: 100%;
    max-width: 1224px;
    margin: 0 auto;
    background: ${colours.lightGrey};
    height: 1px;
    margin-top: 8px;
`;

export const CarsContainer = styled.div`
    display: grid;
    max-width: 1224px;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(190px, max-content));
    gap: 16px;
    justify-content: center;
    margin-top: 24px;
    position: relative;
`;

export const CardLabel = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    color: ${colours.black};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
`;

export const Card = styled.div<ICardProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${colours.white};
    width: 184px;
    font-size: 16px;
    position: relative;
    :hover {
        cursor: pointer;

        #carLabel {
            color: ${colours.blue};
            font-weight: 700;
            font-size: 16px;
        }
    }

    margin-top: 0.75rem;
    cursor: default;

    ${({ isSelected }) =>
        isSelected
            ? `
        border: 1px solid ${colours.blue};
        border-radius: 4px;

        ${CardLabel} {
            font-weight: 700;
            font-size: 16px;
            color: ${colours.blue};
            padding-bottom: 1rem;
        }
    `
            : ``}
`;

export const CardImage = styled.div`
    display: flex;
    color: ${colours.white};
    background: transparent;
    justify-content: center;
    align-items: center;

    min-width: 10.5rem;
    min-height: 7.5rem;

    img {
        display: flex;
        margin: 0 auto;
        background: none;
        max-width: 10.5rem;
        max-height: 7.5rem;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const SubmitButton = styled.button<IButtonProps>`
    display: flex;
    width: 140px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    justify-content: center;
    align-self: center;
    cursor: pointer;
    background: ${colours.blue};
    color: ${colours.white};

    text-align: center;

    :hover {
        background: ${colours.darkBlue};
    }

    // Show red border around container in case no value was selected in the form
    ${(props) =>
        props.disabled &&
        css`
            background-color: ${colours.lightGrey};
            border: none;

            :hover {
                background: ${colours.lightGrey};
            }
        `}

    @media (max-width: 768px) {
        width: 200px;
    }
`;
