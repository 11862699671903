import styled, { css } from 'styled-components';

import { colours } from '../../constants/colours';

interface IButtonProps {
    disabled: boolean;
}

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const SubmitButton = styled.button<IButtonProps>`
    display: flex;
    width: 140px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    align-self: center;
    cursor: pointer;
    background: ${colours.blue};
    color: ${colours.white};
    border: none;
    text-align: center;

    :hover {
        background: ${colours.darkBlue};
    }

    // Show red border around container in case no value was selected in the form
    ${(props) =>
        props.disabled &&
        css`
            background-color: ${colours.lightGrey};
            border: none;

            :hover {
                background: ${colours.lightGrey};
            }
        `}

    @media (max-width: 768px) {
        width: 200px;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    margin-right: 0.5rem;
`;
