import React from 'react';

import Backdrop from '@mui/material/Backdrop';

import { useSelector } from 'react-redux';

import Loader from '../Loader/Loader';
import ProgressBar from '../ProgressBar';

// Slices
import { selectUploadProgress } from '../../slices/fileSlice';

interface IProps {
    open: boolean;
    progressBar?: boolean;
}

const defaultProps = {
    progressBar: false,
};

const BackdropLoader: React.FunctionComponent<IProps> = (props: IProps) => {
    const { open, progressBar } = props;
    const uploadProgress = useSelector(selectUploadProgress);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            style={{ justifyContent: 'center', flexDirection: 'column' }}
        >
            <Loader />
            {progressBar && <ProgressBar progress={uploadProgress} />}
        </Backdrop>
    );
};

BackdropLoader.defaultProps = defaultProps;

export default BackdropLoader;
