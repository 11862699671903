import type { PayloadAction } from '@reduxjs/toolkit';

import type { IBrandColorUpdateResponseSuccess } from '../slices/brandSlice';
import type { IBrandType } from '../types/brands/index';

export const updateBrandsStateObject = (
    brandState: IBrandType[],
    action: PayloadAction<IBrandColorUpdateResponseSuccess>,
) => {
    const newBrandState = [...brandState];

    const targetBrandIndex = newBrandState.findIndex((item) => item.id === action.payload.data.id);
    if (targetBrandIndex >= 0) {
        newBrandState[targetBrandIndex] = {
            ...newBrandState[targetBrandIndex],
            ...action.payload.data,
        };
    }

    return newBrandState;
};
