export const parseNull = (str?: string | null) => {
    if (!str) return null;
    if (str === '') return null;
    return str;
};

export const parseDefaultString = (str: string | null | undefined, defaultString: string) => {
    if (!str) return defaultString;
    return str;
};
