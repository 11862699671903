import React from 'react';

import type { ICarsContainerProps } from './types';

import CarsCard from '../CarsCard/CarsCard';

import { Container } from './styles';

const CarsContainer: React.FC<ICarsContainerProps> = (props: ICarsContainerProps) => {
    const { availableCars, onSelect, selectedCarId } = props;

    return (
        <Container>
            {availableCars.length > 0 &&
                availableCars.map((car) => (
                    <CarsCard
                        key={car.id}
                        selectedFullCar={car}
                        carIsSelected={car.id === Number(selectedCarId)}
                        handleSelectedCar={(selectedCar) => onSelect(selectedCar)}
                    />
                ))}
        </Container>
    );
};
export default CarsContainer;
