import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { axiosInstance } from '../axiosInstance';
import { updateBrandsStateObject } from '../utils/brandSlice-helper';

import type { RootState } from '../store';
import type { IBrandType } from '../types/brands/index';

import type { IError } from '../types/errors/index';

import { BACKEND_ROUTES } from '../constants/api.routes';

export interface IBrandState {
    brand: IBrandType | null;
    brands: IBrandType[] | null;
    errors: IError | null;
    loading: boolean;
}

export interface IResponseFailure extends IError {}

export interface IBrandResponseSuccess {
    data: IBrandType;
}

export interface IBrandColorUpdateResponseSuccess {
    data: IBrandType;
}

export interface IBrandsResponseSuccess {
    data: IBrandType[];
}

export const initialState: IBrandState = {
    brand: null,
    brands: null,
    errors: null,
    loading: false,
};

export const fetchBrands = createAsyncThunk('/brand', (_, { dispatch }) => {
    axiosInstance
        .get<IBrandsResponseSuccess>(BACKEND_ROUTES.BRAND, {})
        .then((response) => {
            dispatch(setBrandsAction(response.data));
        })
        .catch((err: IResponseFailure) => {
            dispatch(setErrorsAction(err));
        });
});

export const fetchBrand = createAsyncThunk(
    '/brand/by-name',
    (params: { brandName: string }, { dispatch }) => {
        axiosInstance
            .get<IBrandResponseSuccess>(
                `${BACKEND_ROUTES.BRAND_BY_NAME}${params.brandName.toUpperCase()}`,
                {},
            )
            .then((response) => {
                dispatch(setBrandAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const fetchBrandById = createAsyncThunk(
    '/brand/:brand_id',
    (params: { brandId: string }, { dispatch }) => {
        axiosInstance
            .get<IBrandResponseSuccess>(`${BACKEND_ROUTES.BRAND}/${params.brandId}`, {})
            .then((response) => {
                dispatch(setBrandAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const doUpdateBrandColor = createAsyncThunk(
    'PUT/brand/:brand_id',
    (params: { brandId: number; hexCode: string | null }, { dispatch }) => {
        const { brandId, hexCode } = params;
        axiosInstance
            .put<IBrandColorUpdateResponseSuccess>(`${BACKEND_ROUTES.ADMIN_BRAND}/${brandId}`, {
                hexCode,
            })
            .then((response) => {
                dispatch(updateBrandAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const slice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        updateBrandAction(state, action: PayloadAction<IBrandColorUpdateResponseSuccess>) {
            if (state.brands) {
                state.brands = updateBrandsStateObject(state.brands, action);
            }
            state.brand = action.payload.data;
            state.loading = false;
            state.errors = null;
        },
        setBrandAction(state, action: PayloadAction<IBrandResponseSuccess>) {
            state.brand = action.payload.data;
            state.loading = false;
            state.errors = null;
        },
        setBrandsAction(state, action: PayloadAction<IBrandsResponseSuccess>) {
            state.brands = action.payload.data;
            state.loading = false;
            state.errors = null;
        },
        setErrorsAction(state, action: PayloadAction<IResponseFailure | null>) {
            state.brand = null;
            state.errors = action.payload;
            state.loading = false;
        },
        clearBrandAction(state) {
            state.brand = null;
            state.loading = false;
            state.errors = null;
        },
    },
});

export const selectBrand = (state: RootState) => state.brand.brand;
export const selectBrands = (state: RootState) => state.brand.brands;
export const selectBrandLoading = (state: RootState) => state.brand.loading;
export const selectBrandErrors = (state: RootState) => state.brand.errors;

export const {
    updateBrandAction,
    setBrandAction,
    setBrandsAction,
    setErrorsAction,
    clearBrandAction,
} = slice.actions;

export default slice.reducer;
