import styled from 'styled-components';
import { Button } from '@mui/material';

import { colours } from '../../constants/colours';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
`;

interface IDropZoneWrapperProps {
    droppedFile: number;
}
export const DropZoneWrapper = styled.div<IDropZoneWrapperProps>`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${({ droppedFile }) => (droppedFile > 0 ? colours.white : '#eeeeee')};
    border-style: dashed;
    background-color: ${({ droppedFile }) =>
        droppedFile > 0 ? colours.lightestBlue : colours.lightestGrey};
    color: ${({ droppedFile }) => (droppedFile > 0 ? colours.blue : '#bdbdbd')};
    outline: none;
    transition: border 0.24s ease-in-out;
    text-align: center;
`;
export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ButtonContainer = styled(Button)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    margin: 2rem auto;
    &:disabled {
        color: ${colours.mediumGrey};
    }
`;
export const WarningMessage = styled.p`
    color: ${colours.red};
`;
