import styled from 'styled-components';
import { Button } from '@mui/material';

import { colours } from '../../constants/colours';
import { minSizes, maxSizes } from '../../constants/sizes';

import type { InputWrapperProps } from './types';
import { EditorContainer } from '../../components/MarkdownEditor/styles';

const StyledH1 = styled.h1`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${colours.black};
`;

const StyledH2 = styled.h2`
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: ${colours.blue};
`;

const StyledH3 = styled.h3`
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: ${colours.blue};
`;

const StyledP = styled.p`
    @media ${minSizes.small} {
        font-size: 0.8rem;
        line-height: 1rem;
    }

    @media ${minSizes.large} {
        font-size: 1rem;
        line-height: 1.5rem;
    }
`;

export const Stepper = styled(StyledH2)``;
export const UpsellName = styled(StyledH3)``;

export const ActionButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const Container = styled.div`
    max-width: 76.5rem;
    width: 98%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    margin: 0 auto;
`;

export const Section = styled.div`
    width: 100%;
`;

export const ButtonContainer = styled(Button)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    margin: 2rem auto;
`;

export const PageHeadlineContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0.5rem 0;
`;

export const PageTitle = styled(StyledH1)``;

export const PageSubTitle = styled(StyledP)`
    margin: 0.5rem 0;

    color: ${colours.darkGrey};
`;

export const UpsellContainer = styled.div`
    padding: 0 1rem;
`;

export const FormContainer = styled.div`
    padding: 0 1rem;
    margin: 1rem 0;
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const InputsLineContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const InputWrapper = styled.div<InputWrapperProps>`
    flex: ${({ size = 1 }) => size};
    padding: ${({ position }) => {
        switch (position) {
            case 'start':
                return '0 0.25rem 0 0';
            case 'end':
                return '0 0 0 0.25rem';
            case 'middle':
                return '0 0.25rem';
            default:
                return '0';
        }
    }};

    & > label,
    & > div.MuiInputBase-root {
        margin-top: 0.5rem;
    }

    ${EditorContainer} {
        padding-bottom: 1.3rem;
    }
`;
