import styled from 'styled-components';

// Constants
import { colours } from '../../constants/colours';

export const StyledLink = styled.a`
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${colours.white};
    text-decoration: none;

    :hover {
        color: ${colours.lightBlue};
    }
`;

export const Container = styled.div`
    width: 100%;
    padding: 1rem;
    text-align: center;
    background-color: ${colours.blue};
`;
