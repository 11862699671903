import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Slices
import { selectSelectedFullCar } from '../../../../slices/carsSlice';
import { selectBrand } from '../../../../slices/brandSlice';
import { selectSelectedUpsell } from '../../../../slices/upsellsSlice';

// Components
import Form from '../../../../components/Form/Form';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import SelectWarrantyStartDate from './formSelectors/SelectWarrantyStartDate';
import SelectSubscriptionDate from './formSelectors/SelectSubscriptionDate';
import SelectContract from './formSelectors/SelectContract';
import SelectDuration from './formSelectors/SelectDuration';
import SelectMileage from './formSelectors/SelectMileage';

// Types

import type { IContractSelections } from '../../../../types/contracts';

// Styles
import { FormContainer } from '../styles';

// Routes
import { APP_ROUTES } from '../../../../constants/app.routes';

// Utils
import {
    formComplete,
    generatePathParams,
    lissageCarsPageComplete,
} from '../../../../utils/contract-helper';

export interface FormProps {
    formSelections: IContractSelections | null;
}

const LissageForm: React.FC<FormProps> = (props: FormProps) => {
    const { formSelections } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Store
    const selectedCar = useSelector(selectSelectedFullCar);
    const selectedBrand = useSelector(selectBrand);
    const upsell = useSelector(selectSelectedUpsell);

    const handleSubmit = () => {
        if (
            upsell &&
            selectedBrand &&
            selectedCar &&
            formSelections &&
            formComplete(formSelections)
        ) {
            navigate(
                generatePath(
                    APP_ROUTES.LISSAGE_CONTRACT,
                    generatePathParams(selectedBrand, selectedCar, formSelections, upsell),
                ),
            );
        }
    };

    return (
        <FormContainer>
            <Form>
                <SelectWarrantyStartDate initialDate={formSelections?.initialDate ?? null} />
                {selectedBrand && selectedCar && (
                    <SelectSubscriptionDate
                        formSelections={formSelections}
                        selectedCar={selectedCar}
                        selectedBrand={selectedBrand}
                    />
                )}
                {selectedBrand && selectedCar && (
                    <SelectContract
                        formSelections={formSelections}
                        selectedCar={selectedCar}
                        selectedBrand={selectedBrand}
                    />
                )}
                <SelectDuration formSelections={formSelections} />
                <SelectMileage formSelections={formSelections} />
            </Form>
            <ActionButton
                title={t('global.submitButton')}
                disabled={!lissageCarsPageComplete(formSelections, selectedCar, upsell)}
                submitData={() => handleSubmit()}
            />
        </FormContainer>
    );
};

export default LissageForm;
