import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Provider } from 'react-redux';

import Routes from './Routes';

import store from './store';

import Layout from './components/Layout/Layout';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Loader from './components/Loader/Loader';

const App = (): JSX.Element => (
    <Layout>
        <Provider store={store}>
            <Suspense fallback={<Loader />}>
                <Header />
                <Routes />
                <Footer />
            </Suspense>
        </Provider>
        {/* transfered the <Routes /> inside the Suspense tag to fix error (Error boundary) */}
        {/* <Routes /> */}
    </Layout>
);

export default App;
