import styled from 'styled-components';

import type { IFullWidthProp } from '../../types/index';

export const SelectContains = styled.div`
    display: flex;
    align-items: center;
    max-width: 1224px;
    width: 100%;
    @media (max-width: 1225px) {
        width: 100%;
    }
`;
export const SelectGroupContainer = styled.div<IFullWidthProp>`
    display: grid;
    max-width: 1224px;
    width: 100%;
    grid-template-columns: repeat(
        auto-fit,
        minmax(392px, ${({ fullWidth }) => (fullWidth ? '1fr' : 'max-content')})
    );
    gap: 24px;
    justify-content: center;
    margin: 24px 0px;
`;
