import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { axiosInstance } from '../axiosInstance';

import type { RootState } from '../store';
import type { IServiceType, IAddedServiceType } from '../types/services';

import type { IError } from '../types/errors/index';

import { BACKEND_ROUTES } from '../constants/api.routes';

export interface IServicesState {
    addedService: IAddedServiceType | null;
    deletedServiceId: number | null;
    updatedService: IServiceType | null;
    errors: IError | null;
    loading: boolean;
}

export interface IResponseFailure extends IError {}

interface IServiceUpdateResponseSucess {
    data: IServiceType;
}

interface IServiceAddResponseSucess {
    data: IAddedServiceType;
}

export const initialState: IServicesState = {
    addedService: null,
    deletedServiceId: null,
    updatedService: null,
    errors: null,
    loading: false,
};

export const doAddNewService = createAsyncThunk(
    'POST/admin/service',
    (
        params: {
            upsellId: number;
            name: string | null;
            description: string | null;
            hexCode: string | null;
            displayPriority: number;
        },
        { dispatch },
    ) => {
        const { upsellId, name, description, hexCode, displayPriority } = params;
        axiosInstance
            .post<IServiceAddResponseSucess>(String(BACKEND_ROUTES.ADMIN_SERVICE), {
                upsellId,
                name,
                description,
                hexCode,
                displayPriority,
            })
            .then((response) => {
                dispatch(setAddedServiceAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const doUpdateService = createAsyncThunk(
    'PUT/admin/service/:service_id',
    (
        params: {
            serviceId: number;
            name: string | null;
            description: string | null;
            hexCode: string | null;
            displayPriority: number;
        },
        { dispatch },
    ) => {
        const { serviceId, name, description, hexCode, displayPriority } = params;
        axiosInstance
            .put<IServiceUpdateResponseSucess>(`${BACKEND_ROUTES.ADMIN_SERVICE}/${serviceId}`, {
                name,
                description,
                hexCode,
                displayPriority,
            })
            .then((response) => {
                dispatch(setUpdatedServiceAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const doDeleteService = createAsyncThunk(
    'DELETE/admin/service/:service_id',
    (params: { serviceId: number }, { dispatch }) => {
        axiosInstance
            .delete(`${BACKEND_ROUTES.ADMIN_SERVICE}/${params.serviceId}`, {})
            .then(() => {
                dispatch(setDeletedServiceIdAction(params.serviceId));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const slice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        setDeletedServiceIdAction(state, action) {
            state.deletedServiceId = action.payload as number;
            state.errors = null;
            state.loading = false;
        },
        setUpdatedServiceAction(state, action: PayloadAction<IServiceUpdateResponseSucess>) {
            state.updatedService = action.payload.data;
            state.errors = null;
            state.loading = false;
        },
        setAddedServiceAction(state, action: PayloadAction<IServiceAddResponseSucess>) {
            state.addedService = action.payload.data;
            state.errors = null;
            state.loading = false;
        },
        setErrorsAction(state, action: PayloadAction<IResponseFailure | null>) {
            state.deletedServiceId = null;
            state.updatedService = null;
            state.errors = action.payload;
            state.loading = false;
        },
        clearAddedServiceAction(state) {
            state.addedService = null;
        },
        clearServicesAction(state) {
            state.deletedServiceId = null;
            state.updatedService = null;
            state.addedService = null;
            state.loading = false;
            state.errors = null;
        },
    },
    extraReducers(builder) {
        builder.addCase(doAddNewService.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(doUpdateService.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(doDeleteService.pending, (state) => {
            state.loading = true;
        });
    },
});

export const selectServiceLoading = (state: RootState) => state.services.loading;
export const selectDeletedServiceId = (state: RootState) => state.services.deletedServiceId;
export const selectUpdatedService = (state: RootState) => state.services.updatedService;
export const selectAddedService = (state: RootState) => state.services.addedService;
export const selectServicesErrors = (state: RootState) => state.services.errors;

export const {
    setAddedServiceAction,
    setUpdatedServiceAction,
    setDeletedServiceIdAction,
    setErrorsAction,
    clearAddedServiceAction,
    clearServicesAction,
} = slice.actions;

export default slice.reducer;
