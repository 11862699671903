import moment from 'moment';

// Constants
import { FrenchDateFormat, IsoDateFormat } from '../constants/dateFormats';

export const formatFrenchDate = (date: string | undefined) =>
    date
        ? moment(new Date(date)).format(FrenchDateFormat)
        : moment(new Date()).format(FrenchDateFormat);

export const formatIsoDate = (date: string | undefined) =>
    date ? moment(new Date(date)).format(IsoDateFormat) : moment(new Date()).format(IsoDateFormat);

export const valueAsDate = (date: string | undefined | null) =>
    date ? new Date(String(date)) : null;
