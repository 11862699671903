import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// Slices
import { selectCarContracts } from '../../../../../slices/carsSlice';
import { updateSelections } from '../../../../../slices/contractsSlice';
import { selectSelectedUpsell } from '../../../../../slices/upsellsSlice';

// Components
import Select from '../../../../../components/Select/Select';

// Types
import { ContractFields, ClearFields } from '../../../../../types/contracts';
import type { IContractSelections } from '../../../../../types/contracts';

// Utils
import { renderSelectedDuration } from '../../../../../utils/formatDropdownValue';
import { indexToClearFrom, filterDuration } from '../../../../../utils/contract-helper';

export interface FormProps {
    formSelections: IContractSelections | null;
}

const SelectDuration: React.FC<FormProps> = (props: FormProps) => {
    const { formSelections } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Store
    const availableCarContracts = useSelector(selectCarContracts);
    const upsell = useSelector(selectSelectedUpsell);

    const availableDuration = useMemo(
        () => filterDuration(availableCarContracts, upsell, t),
        [availableCarContracts, upsell, t],
    );

    const clearSubsequentContractSelections = (updatedField: string) => {
        const initialIndex = indexToClearFrom(updatedField);
        for (let i = initialIndex; i < ClearFields.length; i++) {
            dispatch(updateSelections({ field: ClearFields[i], value: '' }));
        }
    };

    const handleDurationSelection = (field: string, value: string) => {
        dispatch(updateSelections({ field, value }));
        clearSubsequentContractSelections(ContractFields.duration);
    };

    return (
        <Select
            id="selectDuration"
            selectLabel={t(`pages.tierTool.cars.durationLabel`)}
            value={formSelections?.duration ? String(formSelections.duration) : ''}
            options={availableDuration}
            setValue={(newDuration) =>
                handleDurationSelection(ContractFields.duration, String(newDuration))
            }
            disabled={!formSelections?.upsellLevel}
            renderValue={(selected) => {
                if (selected && formSelections?.duration) {
                    return renderSelectedDuration(selected, formSelections.duration, t);
                }
                return t(`global.dropdown.default`);
            }}
            displayEmpty
        />
    );
};

export default SelectDuration;
