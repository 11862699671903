import React from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import formatToPrice from '../../../../utils/formatToPrice';
import generateUpsellImageName from '../../../../utils/generateUpsellImageName';
import { getMappedServicesObject } from '../utils';
import {
    renderSelectedDuration,
    renderSelectedMileage,
} from '../../../../utils/formatDropdownValue';

// Assets
import { ChevronForwardOutline, DurationIcon, MileageIcon } from '../../../../assets/icons';

// Components
import S3Image from '../../../../components/S3Image/S3Image';
import Accordion from '../../../../components/Accordion';
import DisplayField from '../../../../components/DisplayField/DisplayField';

// Styles
import {
    BundleWrapper,
    Bundle,
    ImageContainer,
    NameContainer,
    Name,
    ChevronWrapper,
    PriceTagWrapper,
    PriceTag,
    Price,
    BundleServicesContainer,
    DetailsWrapper,
    NoContractsMessage,
} from './styles';

// Types
import type { IBundlesProps } from '../types';

// Constants
import { colours } from '../../../../constants/colours';
import { defaultCubesImage } from '../../../../constants/upsells';

interface NonExistingContract {
    id: number | null;
    duration: number | string | undefined;
    mileage: number | string | undefined;
    cashPrice: string | null;
    subscriptionPrice: string | null;
    subscriptionDeadline: number | null;
    upsellLevel: number | null;
    displayPriority: number | null;
    name: string | null | undefined;
}

const Bundles: React.FC<IBundlesProps> = ({
    selectedBrand,
    selectedUpsells,
    selectedContracts,
    carImage,
}) => {
    const { t } = useTranslation();
    const contractsFiltered: NonExistingContract[] = [];

    for (let upsellLevel = 1; upsellLevel <= 3; upsellLevel++) {
        selectedContracts
            .filter((el) => el.upsellLevel)
            .map((contract) => {
                if (contract.upsellLevel === upsellLevel) {
                    contractsFiltered.push(contract);
                }
            });
    }

    return (
        <>
            {contractsFiltered.map((contract, contractIndex, contractsArray) => {
                const upsellsArray = selectedUpsells;

                // NOTE: Changed the upsell filter from using level === contract.upselllevel since the contract upselllevel
                // doesn't match with the upsell level for some contracts
                const upsell = upsellsArray.find((el) => el.name === contract.name);

                if (upsell) {
                    return (
                        <BundleWrapper key={contract.name}>
                            <Bundle>
                                <ImageContainer className="car">
                                    <S3Image name={carImage} />
                                </ImageContainer>

                                <NameContainer>
                                    <Name>{contract.name}</Name>
                                </NameContainer>

                                <ImageContainer className="cube">
                                    <S3Image
                                        name={generateUpsellImageName({
                                            brand: selectedBrand.name,
                                            upsellName: String(contract.name),
                                        })}
                                        onErrorSrc={defaultCubesImage}
                                    />
                                    {contractsArray.length > 1 &&
                                    contractsFiltered.length > 1 &&
                                    contractIndex < contractsArray.length - 1 ? (
                                        <ChevronWrapper>
                                            <ChevronForwardOutline />
                                        </ChevronWrapper>
                                    ) : null}
                                </ImageContainer>

                                <PriceTagWrapper>
                                    {contract.id === null ? (
                                        <NoContractsMessage
                                            bgColorHexCode={
                                                selectedBrand.hexCode ??
                                                colours.defaultPriceTagColor
                                            }
                                        >
                                            {t('global.errors.NoContractsAvailable')}
                                        </NoContractsMessage>
                                    ) : (
                                        <PriceTag
                                            bgColorHexCode={
                                                selectedBrand.hexCode ??
                                                colours.defaultPriceTagColor
                                            }
                                        >
                                            <Price>
                                                {`${formatToPrice(Number(contract.cashPrice))} ${t(
                                                    'global.currencySymbol',
                                                )}`}
                                            </Price>
                                        </PriceTag>
                                    )}
                                </PriceTagWrapper>

                                <DetailsWrapper>
                                    <DisplayField
                                        title={renderSelectedDuration(
                                            contract.duration,
                                            contract.duration,
                                            t,
                                        )}
                                    >
                                        <DurationIcon />
                                        {t(`pages.tierTool.cars.durationLabel`)}
                                    </DisplayField>
                                    <DisplayField
                                        title={renderSelectedMileage(
                                            contract.mileage,
                                            contract.duration,
                                            t,
                                        )}
                                    >
                                        <MileageIcon />
                                        {t(`pages.tierTool.cars.mileageLabel`)}
                                    </DisplayField>
                                </DetailsWrapper>

                                {/* Only show services on existing contracts */}
                                {contract.id !== null && (
                                    <BundleServicesContainer>
                                        {getMappedServicesObject(
                                            upsell,
                                            contractIndex,
                                            upsellsArray,
                                        ).map((service) => (
                                            <Accordion
                                                key={service.id}
                                                service={service}
                                                defaultExpanded={service.defaultExpanded}
                                            />
                                        ))}
                                    </BundleServicesContainer>
                                )}
                            </Bundle>
                        </BundleWrapper>
                    );
                } else {
                    return null;
                }
            })}
        </>
    );
};

export default Bundles;
