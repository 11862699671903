import React, { useCallback } from 'react';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';

interface IProps {
    content: string;
}

const MarkdownRenderer: React.FC<IProps> = ({ content }) => {
    const underlineTransformer = useCallback((text: string) => {
        const regex = /(\+\+)/;
        let replacedText = text;
        let isClosingTag = false;
        while (regex.test(replacedText)) {
            replacedText = replacedText.replace(regex, !isClosingTag ? '<u>' : '</u>');
            isClosingTag = !isClosingTag;
        }
        return replacedText;
    }, []);

    return (
        <ReactMarkdown
            rehypePlugins={[
                rehypeRaw,
                [
                    rehypeSanitize,
                    { ...defaultSchema, tagNames: ['p', 'strong', 'em', 'u', 'ul', 'li'] },
                ],
            ]}
        >
            {underlineTransformer(content)}
        </ReactMarkdown>
    );
};

export default MarkdownRenderer;
