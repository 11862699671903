import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// Slices
import { selectCarContracts, updateUpsellFormSelections } from '../../../../../slices/carsSlice';

// Components
import Select from '../../../../../components/Select/Select';

// Types
import { ContractFields } from '../../../../../types/contracts';
import type { IContractSelections } from '../../../../../types/contracts';

// Utils
import { renderSelectedDuration } from '../../../../../utils/formatDropdownValue';
import { filterDurationUpsell } from '../../../../../utils/upsell-helper';

export interface FormProps {
    formSelections: IContractSelections | null;
}

const SelectDuration: React.FC<FormProps> = (props: FormProps) => {
    const { formSelections } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Store
    const availableCarContracts = useSelector(selectCarContracts);

    const availableDuration = useMemo(
        () => filterDurationUpsell(availableCarContracts, t),
        [availableCarContracts, t],
    );

    const handleDurationSelection = (field: string, value: string) => {
        dispatch(updateUpsellFormSelections({ field, value }));
    };

    return (
        <Select
            id="selectDuration"
            selectLabel={t(`pages.tierTool.cars.durationLabel`)}
            value={formSelections?.duration ? String(formSelections.duration) : ''}
            options={availableDuration}
            setValue={(newDuration) =>
                handleDurationSelection(ContractFields.duration, String(newDuration))
            }
            disabled={!availableCarContracts}
            renderValue={(selected) => {
                if (selected && formSelections?.duration) {
                    return renderSelectedDuration(selected, formSelections.duration, t);
                }
                return t(`global.dropdown.default`);
            }}
            displayEmpty
        />
    );
};

export default SelectDuration;
