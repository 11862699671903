import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { axiosInstance } from '../axiosInstance';

import { BACKEND_ROUTES } from '../constants/api.routes';

import type { ICarsType } from '../types/cars';
import type { IContractSelections, IContractType } from '../types/contracts';
import { CarSliceStatus } from '../types/cars';
import type { IError } from '../types/errors/index';
import type { RootState } from '../store';

export interface ICarsState {
    cars: ICarsType[];
    carContracts: IContractType[];
    selectedCar: { carId: number; carName: string } | null;
    selectedFullCar: ICarsType | null;
    selectedDuration: string;
    selectedMileage: string;
    loading: boolean;
    status: string;
    errors: IError | null;
    formSelections: IContractSelections | null;
}

export const initialState: ICarsState = {
    cars: [],
    carContracts: [],
    selectedCar: null,
    selectedFullCar: null,
    selectedDuration: '',
    selectedMileage: '',
    loading: false,
    status: CarSliceStatus.success,
    errors: null,
    formSelections: null,
};

export interface IResponseFailure extends IError {}

export interface IReturnCarContractsSuccess {
    data: {
        id: number;
        fullname: string;
        shortName: string;
        carImage: string;
        relatedContracts: IContractType[];
    };
}

export interface IReturnCarsSuccess {
    data: {
        id: number;
        name: string;
        relatedCars: ICarsType[];
    };
}
export interface IReturnCarSuccess {
    data: ICarsType;
}

export interface IUpdateItem {
    field: string;
    value: string | number | null;
}

export const fetchCars = createAsyncThunk(
    'upsell/fetchCars',
    (params: { brandId: number }, { dispatch }) => {
        axiosInstance
            .get<IReturnCarsSuccess>(
                BACKEND_ROUTES.BRAND_WITH_RELATED_CARS.replace(
                    ':brand_id',
                    params.brandId.toString(),
                ),
            )
            .then((response) => {
                dispatch(setCarsAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const fetchCar = createAsyncThunk('car/fetchCar', (carId: number | string, { dispatch }) => {
    axiosInstance
        .get<IReturnCarSuccess>(`${BACKEND_ROUTES.CARS}/${carId}`)
        .then((response) => {
            dispatch(setSelectedCarFullAction(response.data.data));
        })
        .catch((err: IResponseFailure) => {
            dispatch(setErrorsAction(err));
        });
});

export const fetchCarContracts = createAsyncThunk(
    'upsell/fetchCarContracts',
    (params: { carId: number }, { dispatch }) => {
        axiosInstance
            .get<IReturnCarContractsSuccess>(
                `${BACKEND_ROUTES.CARS}/${params.carId}/with-related-contracts`,
            )
            .then((response) => {
                dispatch(setCarContractsAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const fetchLissageContracts = createAsyncThunk(
    'lissage/fetchLissageContracts',
    (params: { carId: number; initialDate: string; subscriptionDate: string }, { dispatch }) => {
        axiosInstance
            .get<IReturnCarContractsSuccess>(
                `${BACKEND_ROUTES.CARS}/${params.carId}/with-related-lissage-contracts`,
                {
                    params: {
                        initialDate: params.initialDate,
                        subscriptionDate: params.subscriptionDate,
                    },
                },
            )
            .then((response) => {
                dispatch(setCarContractsAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const updateSelectedCar = createAsyncThunk(
    'upsell/setSelectedCar',
    (car: ICarsType | null, { dispatch }) => {
        dispatch(setSelectedCarFullAction(car));
    },
);

export const clearSelectedData = createAsyncThunk('upsell/clearSelections', (_, { dispatch }) => {
    dispatch(clearSelectedDataAction());
});

export const updateUpsellFormSelections = createAsyncThunk(
    'upsell/setFormSelections',
    (params: { field: string; value: string | number | null }, { dispatch }) => {
        dispatch(setFormSelectionsAction(params));
    },
);

export const carsSlice = createSlice({
    name: 'cars',
    initialState,
    reducers: {
        setCarsAction(state, action: PayloadAction<IReturnCarsSuccess>) {
            state.cars = action.payload.data.relatedCars;
            state.loading = false;
            state.errors = null;
        },
        setCarContractsAction(state, action: PayloadAction<IReturnCarContractsSuccess>) {
            state.carContracts = action.payload.data.relatedContracts;
            state.loading = false;
        },
        setAvailableCarContractsAction(state, action: PayloadAction<IReturnCarContractsSuccess>) {
            state.carContracts = action.payload.data.relatedContracts;
            state.loading = false;
        },
        setSelectedCarAction(state, action) {
            state.selectedCar = action.payload as { carId: number; carName: string };
        },
        // TODO: Refactor  setSelectedCarAction to use ICarType setSelectedCarFullAction is intermediate step
        setSelectedCarFullAction(state, action: PayloadAction<ICarsType | null>) {
            state.selectedFullCar = action.payload;
            state.loading = false;
        },
        setSelectedDurationAction(state, action) {
            state.selectedDuration = action.payload as string;
        },
        setSelectedMileageAction(state, action) {
            state.selectedMileage = action.payload as string;
        },
        setErrorsAction(state, action: PayloadAction<IResponseFailure | null>) {
            state.cars = [];
            state.errors = action.payload;
            state.loading = false;
        },
        clearCarsAction(state) {
            state.cars = [];
            state.loading = false;
            state.errors = null;
            state.formSelections = null;
        },
        clearSelectedDataAction(state) {
            state.formSelections = null;
            state.cars = [];
            state.selectedFullCar = null;
            state.selectedCar = null;
        },
        setFormSelectionsAction(state, action: PayloadAction<IUpdateItem>) {
            const key = action.payload.field;
            if (state.formSelections) {
                const previous = state.formSelections;
                state.formSelections = { ...previous, [key]: action.payload.value };
            } else {
                state.formSelections = { [action.payload.field]: action.payload.value };
            }
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchCars.pending, (state) => {
            state.loading = true;
            state.status = CarSliceStatus.loading;
        });
        builder.addCase(fetchCarContracts.pending, (state) => {
            state.loading = true;
            state.status = CarSliceStatus.loading;
        });
    },
});

export const selectAllCars = (state: RootState) => state.cars.cars;
export const selectCarContracts = (state: RootState) => state.cars.carContracts;
export const getCarStatus = (state: RootState) => state.cars.status;
export const selectCarLoading = (state: RootState) => state.cars.loading;
export const selectSelectedCar = (state: RootState) => state.cars.selectedCar;
export const selectSelectedFullCar = (state: RootState) => state.cars.selectedFullCar;
export const selectSelectedDuration = (state: RootState) => state.cars.selectedDuration;
export const selectSelectedMileage = (state: RootState) => state.cars.selectedMileage;
export const selectCarErrors = (state: RootState) => state.cars.errors;
export const selectUpsellFormSelections = (state: RootState) => state.cars.formSelections;

export const {
    setErrorsAction,
    setCarsAction,
    setCarContractsAction,
    setSelectedCarAction,
    setSelectedDurationAction,
    setSelectedMileageAction,
    setSelectedCarFullAction,
    clearSelectedDataAction,
    setFormSelectionsAction,
    clearCarsAction,
} = carsSlice.actions;

export default carsSlice.reducer;
