import React from 'react';

import { Card, CardImage, CardLabel } from './styles';
import type { ICarsType } from '../../types/cars';
import S3Image from '../S3Image/S3Image';
import { smallDefaultCarImage } from '../../constants/cars';

export interface ICarsCardProps {
    carIsSelected: boolean;
    selectedFullCar: ICarsType;
    handleSelectedCar?: (car: ICarsType) => void;
}

const CarsCard: React.FC<ICarsCardProps> = (props: ICarsCardProps) => {
    const { selectedFullCar, handleSelectedCar, carIsSelected } = props;
    const { shortName, carImage } = selectedFullCar;

    return (
        <Card
            onClick={handleSelectedCar ? () => handleSelectedCar(selectedFullCar) : undefined}
            selectedCard={carIsSelected}
        >
            <CardImage>
                <S3Image name={`${carImage}-sm`} onErrorSrc={smallDefaultCarImage} />
            </CardImage>

            <CardLabel id="carLabel">{shortName}</CardLabel>
        </Card>
    );
};
export default CarsCard;
