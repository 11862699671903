import React, { useEffect, useState, useCallback } from 'react';

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorContainer, StyledFieldset, StyledLegend } from './styles';

const extendedStyleItems = {
    UNDERLINE: {
        open: function open() {
            return '++';
        },
        close: function close() {
            return '++';
        },
    },
};

interface MarkdownEditorProps {
    label?: string | null;
    defaultValue: string | null;
    onChange: (value: string) => void;
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = (props) => {
    // Props variables
    const { label, defaultValue, onChange } = props;

    // States
    const [prevValue, setPrevValue] = useState(defaultValue ?? '');
    const [editorState, setEditorState] = useState<EditorState | null>(null);

    // Comparing new value with previous value to know if should pass it to the callback function
    // and update the previous value state
    const doOnchangeEvent = useCallback(
        (markdownString: string) => {
            // Comparing current value and previous value as the editorState will change on *any* actions
            if (prevValue !== markdownString) {
                onChange(markdownString);
                setPrevValue(markdownString);
            }
        },
        [onChange, prevValue],
    );

    // Initiate/Reset editor state with given (prop) default value
    useEffect(() => {
        if (defaultValue) {
            const rawData = markdownToDraft(defaultValue, {
                blockStyles: {
                    // eslint-disable-next-line camelcase -- Uncontrolled module options
                    ins_open: 'UNDERLINE',
                },
                remarkableOptions: {
                    enable: {
                        inline: 'ins',
                    },
                },
            });
            const contentState = convertFromRaw(rawData);
            setEditorState(EditorState.createWithContent(contentState));
        } else {
            setEditorState(EditorState.createEmpty());
        }
    }, [defaultValue]);

    // (If initiated) Update editor state on editorState change
    // Converts the editor (draftjs) raw data into a markdown string
    // then passes it to doOnchangeEvent function
    useEffect(() => {
        if (editorState) {
            const contentState = editorState.getCurrentContent();
            const rawData = convertToRaw(contentState);
            const markdownString = draftToMarkdown(rawData, {
                styleItems: extendedStyleItems,
            });
            doOnchangeEvent(markdownString);
        }
    }, [editorState, doOnchangeEvent]);

    // Render
    return (
        <EditorContainer>
            {editorState && (
                <>
                    <Editor
                        wrapperClassName="editor-wrapper"
                        editorClassName="editor"
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        toolbar={{
                            options: ['inline', 'list'],
                            inline: {
                                inDropdown: false,
                                options: ['bold', 'italic', 'underline'],
                            },
                            list: {
                                inDropdown: false,
                                options: ['unordered'],
                            },
                        }}
                    />
                    <StyledFieldset>
                        {label && (
                            <StyledLegend>
                                <span>{label}</span>
                            </StyledLegend>
                        )}
                    </StyledFieldset>
                </>
            )}
        </EditorContainer>
    );
};

MarkdownEditor.defaultProps = {
    label: null,
};

export default MarkdownEditor;
