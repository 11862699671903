import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const Container = styled.div`
    display: flex;
    height: 184px;
    margin-bottom: 2rem;
    justify-content: center;
`;

export const BrandHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    text-align: center;
`;

export const LogoContainer = styled.div`
    display: flex;
    background: transparent;
    color: white;
    justify-content: center;
    align-items: center;

    img {
        max-width: 88px;
        max-height: 88px;
        object-fit: fill;
    }
`;

export const BrandHeaderService = styled.p`
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    color: ${colours.black};
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 0;

    @media (max-width: 768px) {
        padding: 0 8px;
    }
`;

export const BrandHeaderInfo = styled.p`
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    font-weight: 400;
    color: ${colours.darkGrey};
    margin-top: 16px;

    @media (max-width: 768px) {
        padding: 0 8px;
    }
`;
