import styled, { css } from 'styled-components';

import { colours } from '../../constants/colours';

export interface ICardProps {
    selectedCard: boolean;
}

export const Card = styled.div<ICardProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${colours.white};
    width: 184px;
    font-size: 16px;
    position: relative;
    :hover {
        cursor: pointer;

        #carLabel {
            color: ${colours.blue};
            font-weight: 700;
            font-size: 16px;
        }
    }
    margin-top: 0.75rem;
    // Show border when car is selected
    ${(props) =>
        props.selectedCard &&
        css`
            border: 1px solid ${colours.blue};
            border-radius: 4px;

            #carLabel {
                color: ${colours.blue};
                font-weight: 700;
                font-size: 1rem;
                padding-bottom: 1rem;
            }
        `}
`;

export const CardImage = styled.div`
    display: flex;
    color: ${colours.white};
    background: transparent;
    justify-content: center;
    align-items: center;

    min-width: 10.5rem;
    min-height: 7.5rem;

    img {
        display: flex;
        margin: 0 auto;
        background: none;

        max-width: 10.5rem;
        max-height: 7.5rem;
    }
`;

export const CardLabel = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    color: ${colours.black};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
`;
