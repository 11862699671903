import React from 'react';

// Components
import S3Image from '../../../../components/S3Image/S3Image';
// Helpers & Constants

import { defaultCubesImage } from '../../../../constants/upsells';
import { defaultCarImage } from '../../../../constants/cars';

// Utils
import generateUpsellImageName from '../../../../utils/generateUpsellImageName';

// Styles
import { PicturesGroup, CarContainer, CubesContainer } from '../styles';

// Types

export interface CarAndCubesProps {
    carImage: string;
    brand: string;
    upsellName: string;
}

const CarAndCubes: React.FC<CarAndCubesProps> = (props: CarAndCubesProps) => {
    const { brand, carImage, upsellName } = props;

    return (
        <PicturesGroup>
            <CarContainer>
                <S3Image name={carImage} onErrorSrc={defaultCarImage} />
            </CarContainer>
            <CubesContainer>
                <S3Image
                    name={generateUpsellImageName({ brand, upsellName })}
                    onErrorSrc={defaultCubesImage}
                />
            </CubesContainer>
        </PicturesGroup>
    );
};
export default CarAndCubes;
