import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

import { axiosInstance } from '../axiosInstance';

import type { RootState } from '../store';

import type { IError } from '../types/errors/index';
import { SliceStatus } from '../types/index';

import { BACKEND_ROUTES } from '../constants/api.routes';
import { normalizeName } from '../utils/s3-images-helper';

export interface IFileState {
    files: File[];
    errors: IError | null;
    loading: boolean;
    status: string | null;
    progress: number | null;
}

export interface IResponseFailure extends IError {}

export interface IUploadFilesResponseSuccess {
    data: null;
}

export const initialState: IFileState = {
    files: [],
    errors: null,
    loading: false,
    status: null,
    progress: null,
};

export const uploadImageFiles = createAsyncThunk(
    'file/upload-images',
    (files: File[], { dispatch }) => {
        let uploadProgress = 0;
        dispatch(setFileStatus(SliceStatus.UPLOADING));
        files.map((image) => {
            const fileName = normalizeName(image.name);
            const formData = new FormData();
            formData.append('file', image);
            // no axiosInstance as header includes content-type
            axios
                .post<IUploadFilesResponseSuccess>(
                    `${process.env.REACT_APP_API_URL}${BACKEND_ROUTES.ADMIN_UPLOAD_IMAGES}?filename=${fileName}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('api_token')}`,
                            'content-type': 'multipart/form-data',
                            accept: 'application/json',
                        },
                    },
                )
                .then(() => {
                    const percentCompleted = 100 / files.length;
                    uploadProgress += percentCompleted;
                    dispatch(setUploadProgress(Math.ceil(uploadProgress)));

                    if (Math.ceil(uploadProgress) >= 100) {
                        dispatch(clearFileAction());
                        dispatch(setFileStatus(SliceStatus.SUCCESS));
                        dispatch(setUploadProgress(0));
                        uploadProgress = 0;
                    }
                })
                .catch((err: IResponseFailure) => {
                    dispatch(clearFileAction());
                    dispatch(setErrorsAction(err));
                });
        });
    },
);
export const uploadCSVFile = createAsyncThunk(
    'file/upload-csv',
    async (params: { files: File[]; now: string }, { dispatch }) => {
        const { files, now } = params;
        const fileName = normalizeName(files[0].name);
        const file = files[0];
        const size = 1024 * 3000; // 3MB Section size
        const fileChunks = [];
        let fileChunksIndex = 0; // Section numBER
        for (let cur = 0; cur < file.size; cur += size) {
            fileChunks.push({
                hash: fileChunksIndex++,
                chunk: file.slice(cur, cur + size),
            });
        }
        // To upload
        const uploadList = fileChunks.map((item, index) => {
            const hash = item.hash.toString();
            const hashedName = `${hash}_${fileName}`;
            const formData = new FormData();
            formData.append('filename', fileName);
            formData.append('hash', hash);
            formData.append('file', item.chunk);
            return axios
                .post<IUploadFilesResponseSuccess>(
                    `${process.env.REACT_APP_API_URL}${BACKEND_ROUTES.ADMIN_UPLOAD_CSV_CHUNK}?filename=${fileName}&hash=${hash}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('api_token')}`,
                            'content-type': 'multipart/form-data',
                            accept: 'application/json',
                        },
                    },
                )
                .then((response) => {
                    dispatch(clearFilesAction());
                })
                .catch((err: IResponseFailure) => {
                    dispatch(setErrorsAction(err));
                });
        });
        await Promise.all(uploadList);

        axiosInstance
            .post<IUploadFilesResponseSuccess>(BACKEND_ROUTES.ADMIN_CSV_COMBINE_PROCESS, {
                uploadTime: now,
                hash: fileChunks.length,
                filename: fileName,
            })
            .then((response) => {
                dispatch(clearFilesAction());
                // dispatch(setFileStatus(SliceStatus.SUCCESS));
            })
            .catch((err: IResponseFailure) => {
                // dispatch(setErrorsAction(err));
            });
    },
);

export const checkCSVUpload = createAsyncThunk(
    'file/check-csv-upload',
    (params: { fileName: string; now: string }, { dispatch }) => {
        const { fileName, now } = params;
        const interval = 2; // 2 min interval
        const maxAttempts = 10;
        let attempt = 1;
        const csvCheck = setInterval(() => {
            axiosInstance
                .post<IUploadFilesResponseSuccess>(BACKEND_ROUTES.ADMIN_CSV_UPLOAD_CHECK, {
                    filename: fileName,
                    attempt,
                    interval,
                    uploadTime: now,
                })
                .then((response) => {
                    dispatch(clearFileAction());
                    dispatch(setFileStatus(SliceStatus.SUCCESS));
                    clearInterval(csvCheck);
                })
                .catch((err: IResponseFailure) => {
                    if (attempt > maxAttempts) {
                        dispatch(setErrorsAction(err));
                        clearInterval(csvCheck);
                    } else {
                        attempt++;
                    }
                });
        }, 60000 * interval);
    },
);

export const clearFileStatus = createAsyncThunk('status/file', (_, { dispatch }) => {
    dispatch(setFileStatus(null));
});

export const slice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        setErrorsAction(state, action: PayloadAction<IResponseFailure | null>) {
            state.files = [];
            state.errors = action.payload;
            state.loading = false;
            state.status = SliceStatus.FAILED;
        },
        clearFileAction(state) {
            state.files = [];
            state.loading = false;
            state.errors = null;
        },
        clearFilesAction(state) {
            state.files = [];
            state.errors = null;
            state.progress = null;
        },
        setFileStatus(state, action: PayloadAction<string | null>) {
            state.status = action.payload;
        },
        setUploadProgress(state, action: PayloadAction<number | null>) {
            state.progress = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(uploadImageFiles.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(uploadCSVFile.pending, (state) => {
            state.loading = true;
        });
    },
});

export const selectFileError = (state: RootState) => state.file.errors;
export const selectFileLoading = (state: RootState) => state.file.loading;
export const selectFileStatus = (state: RootState) => state.file.status;
export const selectUploadProgress = (state: RootState) => state.file.progress;

export const {
    setErrorsAction,
    clearFileAction,
    setFileStatus,
    clearFilesAction,
    setUploadProgress,
} = slice.actions;

export default slice.reducer;
