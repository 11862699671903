import styled from 'styled-components';

// Constants
import { colours } from '../../../constants/colours';
import { minSizes, maxSizes } from '../../../constants/sizes';

// Types
import type { IStyledButtonProps } from './types';

//
export const StyledH3 = styled.h3`
    margin: 0;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 133%;

    @media ${maxSizes.medium} {
        font-size: 1.2rem;
    }
`;

const StyledP = styled.p`
    @media ${minSizes.small} {
        font-size: 0.8rem;
        line-height: 1rem;
    }

    @media ${minSizes.large} {
        font-size: 1rem;
        line-height: 1.5rem;
    }
`;

export const StyledSpan = styled.div`
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${colours.white};
`;

export const StyledButton = styled.button<IStyledButtonProps>`
    padding: 0.5rem 1.5rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: ${colours.blue};
    text-transform: uppercase;
    color: ${colours.white};
    cursor: pointer;
    width: 100%;

    &:hover {
        background-color: ${colours.darkBlue};
    }

    &:active,
    &:focus {
        background-color: ${colours.darkerBlue};
    }

    &:disabled {
        background-color: ${colours.lightGrey};
        color: ${colours.mediumGrey};
    }
`;

//
export const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
`;

export const BackButtonWrapper = styled.div`
    max-width: 76.5rem;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    color: ${colours.blue};

    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    svg {
        width: 1.5rem;
        margin-right: 0.5rem;
    }
`;

export const PageContent = styled.div`
    width: 100%;
    margin: 0 auto;
`;

// Bundles section
export const BundlesContainer = styled.div`
    max-width: 76.5rem;
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;

    margin: 0 auto;

    @media ${maxSizes.small} {
        flex-direction: column;
    }
`;
//

export const Divider = styled.div`
    max-width: 76.5rem;
    width: 100%;
    margin: 0 auto 2.5rem;
    border: 1px solid ${colours.lightGrey};
`;

export const RedoButtonContainer = styled.div`
    width: 100%;

    margin-bottom: 2.5rem;
`;

export const RedoButton = styled.div`
    max-width: 17rem;
    margin: 0 auto;
`;

export const DisclaimerContainer = styled.div`
    max-width: 76.5rem;
    width: 100%;
    margin: 0 auto;
`;

export const PrintButtonCustomStyle = {
    background: String(colours.defaultPriceTagColor),
    width: '17rem',
    height: '40px',
    marginBottom: '1rem',
};
