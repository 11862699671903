import type { AxiosInstance } from 'axios';
import axios from 'axios';

export const axiosInstance: AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: 15000,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('api_token')}`,
    },
});

axiosInstance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error.response.data.status) {
            // Custom error body returned
            return Promise.reject(error.response.data);
        } else {
            return Promise.reject(error);
        }
    },
);
