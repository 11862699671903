import styled, { css } from 'styled-components';

import { Select, InputLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { colours } from '../../constants/colours';

export const SelectContainer = styled.div`
    display: inline-block;
    height: 72px;
    max-width: 600px;
    width: 100%;

    /* FormControl around the select */
    .MuiFormControl-root {
        display: flex;
        width: 100%;
        max-width: 600px;
        height: 42px;

        /* Date Picker Icon color */
        svg {
            path {
                fill: ${colours.mediumGrey};
            }
        }
    }

    /* Border of datePicker */
    .MuiOutlinedInput-root {
        border: 1px solid ${colours.lightGrey};
    }

    /* Disable the hover border on datePicker */
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    @media (max-width: 1225px) {
        max-width: 100%;
        width: 100%;
    } ;
`;

export const StyledInputLabel = styled(InputLabel)`
    height: 24px;
    color: ${colours.black};
    font-size: 16px;
    margin-bottom: 8px;

    @media (max-width: 1225px) {
        height: 24px;
        color: ${colours.black};
        font-size: 16px;
    } ;
`;

export const StyledSelect = styled(Select)`
    ${(props) =>
        props.value === '' &&
        css`
            color: ${colours.lightGrey};
            width: 100%;

            :hover {
                border: none;
                background: ${colours.white};
            }
        `}

    ${(props) =>
        props.value === 0 &&
        css`
            background-color: ${colours.lightGrey};
            border: none;

            :hover {
                background: ${colours.lightGrey};
            }
        `}

    @media (max-width: 1225px) {
        display: flex;
        align-items: center;
        max-width: 100%;
        height: 40px;
        background: none;
        border: 1px solid ${colours.mediumGrey};

        /* Dropdown Placeholder */
        p {
            color: ${colours.mediumGrey};
        }
    } ;
`;

export const StyledDatePicker = styled(DatePicker)``;
