import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import { axiosInstance } from '../axiosInstance';
import { updateUpsellStateObject } from '../utils/upsellSlice-helper';

import type { RootState } from '../store';
import type { IUpsellType } from '../types/upsells/index';

import type { IError } from '../types/errors/index';

import { BACKEND_ROUTES } from '../constants/api.routes';

export interface IUpsellsState {
    upsells: IUpsellType[] | null;
    updatedUpsell: IUpsellType | null;
    selectedUpsell: IUpsellType | null;
    errors: IError | null;
    loading: boolean;
}

export interface IResponseFailure extends IError {}

export interface IUpsellResponseSuccess {
    data: IUpsellType[];
}

export interface IUpsellUpdateResponseSuccess {
    data: IUpsellType;
}

export const initialState: IUpsellsState = {
    upsells: null,
    updatedUpsell: null,
    selectedUpsell: null,
    errors: null,
    loading: false,
};

export interface IReturnUpsellSuccess {
    data: IUpsellType;
}

export const fetchUpsells = createAsyncThunk(
    '/brand/:brand_id/upsells',
    (params: { brandId: string }, { dispatch }) => {
        axiosInstance
            .get<IUpsellResponseSuccess>(`${BACKEND_ROUTES.BRAND}/${params.brandId}/upsells`, {})
            .then((response) => {
                dispatch(setUpsellsAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const refreshUpsellServices = createAsyncThunk(
    '/upsell/:upsell_id/with-related-services',
    (params: { upsellId: number }, { dispatch }) => {
        axiosInstance
            .get<IUpsellUpdateResponseSuccess>(
                `${BACKEND_ROUTES.UPSELL}/${params.upsellId}/with-related-services`,
                {},
            )
            .then((response) => {
                dispatch(updateUpsellAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const fetchLissageSingleUpsell = createAsyncThunk(
    'lissage/fechLissageSingleUpsell',
    (params: { upsellId: string }, { dispatch }) => {
        axiosInstance
            .get<IReturnUpsellSuccess>(
                `${BACKEND_ROUTES.UPSELL}/${params.upsellId}/with-related-services`,
            )
            .then((response) => {
                dispatch(setSelectedUpsellAction(response.data.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const updateUpsell = createAsyncThunk(
    'PUT/upsell/:upsell_id',
    (
        params: { upsellId: number; legal: string | null; contractDisplayPriority: number | null },
        { dispatch },
    ) => {
        const { upsellId, legal, contractDisplayPriority } = params;
        axiosInstance
            .put<IUpsellUpdateResponseSuccess>(`${BACKEND_ROUTES.ADMIN_UPSELL}/${upsellId}`, {
                legal,
                contractDisplayPriority,
            })
            .then((response) => {
                dispatch(updateUpsellAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const updateSelectedUpsell = createAsyncThunk(
    'lissage/setSelectedUpsell',
    (upsell: IUpsellType | null, { dispatch }) => {
        dispatch(setSelectedUpsellAction(upsell));
    },
);

export const clearUpsellData = createAsyncThunk('lissage/clearUpsell', (_, { dispatch }) => {
    dispatch(clearUpsellsAction());
});

export const slice = createSlice({
    name: 'upsells',
    initialState,
    reducers: {
        setUpsellsAction(state, action: PayloadAction<IUpsellResponseSuccess>) {
            state.upsells = action.payload.data;
            state.loading = false;
            state.errors = null;
        },
        updateUpsellAction(state, action: PayloadAction<IUpsellUpdateResponseSuccess>) {
            if (state.upsells) {
                state.upsells = updateUpsellStateObject(state.upsells, action);
            }
            state.updatedUpsell = action.payload.data;
            state.loading = false;
            state.errors = null;
        },
        setSelectedUpsellAction(state, action: PayloadAction<IUpsellType | null>) {
            state.selectedUpsell = action.payload;
        },
        setErrorsAction(state, action: PayloadAction<IResponseFailure | null>) {
            state.upsells = null;
            state.updatedUpsell = null;
            state.errors = action.payload;
            state.loading = false;
        },
        clearUpsellsAction(state) {
            state.upsells = null;
            state.updatedUpsell = null;
            state.loading = false;
            state.errors = null;
            state.selectedUpsell = null;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchUpsells.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(refreshUpsellServices.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateUpsell.pending, (state) => {
            state.loading = true;
        });
    },
});

export const selectUpsellsLoading = (state: RootState) => state.upsells.loading;
export const selectUpsells = (state: RootState) => state.upsells.upsells;
export const selectUpdatedUpsell = (state: RootState) => state.upsells.updatedUpsell;
export const selectSelectedUpsell = (state: RootState) => state.upsells.selectedUpsell;
export const selectUpsellsErrors = (state: RootState) => state.upsells.errors;

export const {
    setUpsellsAction,
    updateUpsellAction,
    setErrorsAction,
    clearUpsellsAction,
    setSelectedUpsellAction,
} = slice.actions;

export default slice.reducer;
