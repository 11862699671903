export const BACKEND_ROUTES = {
    LOGIN: '/auth/login',
    WHO_AM_I: '/auth/whoami',
    CARS: '/car',
    BRAND: '/brand',
    BRAND_BY_NAME: '/brand/by-name/',
    BRAND_WITH_RELATED_CARS: '/brand/:brand_id/with-related-cars',
    UPSELL: '/upsell',
    SERVICE: '/service',
    ADMIN_BRAND: '/admin/brand',
    ADMIN_UPSELL: '/admin/upsell',
    ADMIN_SERVICE: '/admin/service',
    ADMIN_UPLOAD_IMAGES: '/admin/file/upload-images',
    ADMIN_CSV_COMBINE_PROCESS: '/admin/file/combine-and-process-csv',
    ADMIN_UPLOAD_CSV_CHUNK: '/admin/file/upload-chunk-csv',
    CONTRACT: '/contract',
    CONTRACT_BY_PARAMS: '/contract/by-params',
    ADMIN_CSV_UPLOAD_CHECK: '/admin/file/csv-upload-check',
};
