import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Slices
import { selectSelectedFullCar } from '../../../../slices/carsSlice';
import { selectBrand } from '../../../../slices/brandSlice';

// Components
import Form from '../../../../components/Form/Form';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import SelectDuration from './formSelectors/SelectDuration';
import SelectMileage from './formSelectors/SelectMileage';

// Types

import type { IContractSelections } from '../../../../types/contracts';

// Styles
import { FormContainer } from '../styles';

// Routes
import { APP_ROUTES } from '../../../../constants/app.routes';

// Utils
import { upsellCarsPageComplete, upsellFormComplete } from '../../../../utils/upsell-helper';

export interface FormProps {
    formSelections: IContractSelections | null;
}

const UpsellForm: React.FC<FormProps> = (props: FormProps) => {
    const { formSelections } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Store
    const selectedCar = useSelector(selectSelectedFullCar);
    const selectedBrand = useSelector(selectBrand);

    const handleSubmit = () => {
        if (selectedBrand && selectedCar && formSelections && upsellFormComplete(formSelections)) {
            navigate(
                generatePath(APP_ROUTES.UPSELL_CONTRACTS, {
                    brand: selectedBrand.name,
                    brandId: String(selectedBrand.id),
                    carId: String(selectedCar.id),
                    duration: formSelections.duration,
                    mileage: formSelections.mileage,
                }),
            );
        }
    };
    return (
        <FormContainer>
            <Form>
                <SelectDuration formSelections={formSelections} />
                <SelectMileage formSelections={formSelections} />
            </Form>
            <ActionButton
                title={t('global.submitButton')}
                disabled={!upsellCarsPageComplete(formSelections, selectedCar)}
                submitData={() => handleSubmit()}
            />
        </FormContainer>
    );
};

export default UpsellForm;
