import React from 'react';
import Icon from '@mui/material/Icon';

// Styles
import { ButtonContainer, IconContainer, SubmitButton } from './styles';

interface ActionButtonProps {
    title: string;
    disabled: boolean;
    submitData: () => void;
    iconName?: string;
    customStyle?: Record<string, unknown>;
}

const defaultProps = {
    customStyle: {},
    iconName: '',
};

const ActionButton: React.FC<ActionButtonProps> = (props: ActionButtonProps) => {
    const { title, disabled, submitData, iconName, customStyle } = props;

    return (
        <ButtonContainer>
            <SubmitButton
                type="submit"
                data-testid="actionButton"
                disabled={disabled}
                onClick={() => submitData()}
                style={customStyle}
            >
                {iconName ? (
                    <IconContainer>
                        <Icon>{iconName}</Icon>
                    </IconContainer>
                ) : null}
                {title}
            </SubmitButton>
        </ButtonContainer>
    );
};
export default ActionButton;

ActionButton.defaultProps = defaultProps;
