import type { TFunction } from 'react-i18next';

// Types
import type { ICarsType } from '../types/cars';
import type { IContractSelections, IContractType } from '../types/contracts';
import type { IBrandType } from '../types/brands';

// Utils
import { renderDurationOptionLabel, renderMileageOptionLabel } from './formatDropdownValue';

export const upsellCarsPageComplete = (
    selections: IContractSelections | null,
    car: ICarsType | null,
) => Boolean(car && selections && upsellFormComplete(selections));

export const upsellFormComplete = (selections: IContractSelections) =>
    Boolean(selections.duration && selections.mileage);

export const fetchMonteeUpsellsParams = (
    brand: IBrandType,
    car: ICarsType,
    selections: IContractSelections,
) => ({
    brandId: brand.id,
    carId: car.id,
    duration: selections.duration,
    mileage: selections.mileage,
});

export const filterMileageUpsell = (
    contracts: IContractType[],
    selections: IContractSelections | null,
    t: TFunction<'translation'>,
) =>
    contracts
        .filter((contract) => contract.duration === Number(selections?.duration))
        .map(({ mileage }) => ({
            label: renderMileageOptionLabel(mileage, t),
            value: mileage,
        }))
        .filter(
            (mileage, index, self) =>
                self.findIndex((item) => item.value === mileage.value) === index,
        );

export const filterDurationUpsell = (
    contracts: IContractType[],

    t: TFunction<'translation'>,
) =>
    contracts
        .map(({ duration }) => ({
            label: renderDurationOptionLabel(duration, t),
            value: duration,
        }))
        .filter(
            (duration, index, self) =>
                self.findIndex((item) => item.value === duration.value) === index,
        )
        .sort((a, b) => a.value - b.value);
