import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const Container = styled.header`
    background-color: ${colours.blue};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 88px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);

    img {
        display: flex;
        width: 224px;
        height: 40px;
    }
`;
