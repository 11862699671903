import { createGlobalStyle } from 'styled-components';

import '../fonts/fonts.css';

export default createGlobalStyle`

html, #root {
    height: 100%;
    background-color: 'white';
}

body {
    height: 100%;
    margin: 0;

    font-size: 16px; /* 1rem */
    font-weight: 400; /* Regular */
    font-family: 'EncodeSans', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

`;
