export interface IContractType {
    id: number;
    duration: number;
    mileage: number;
    cashPrice: string;
    subscriptionPrice: string;
    subscriptionDeadline: number;
    upsellLevel: number | null;
    displayPriority: number;
    name: string;
}

export const ContractFields = {
    duration: 'duration',
    mileage: 'mileage',
    subscriptionPrice: 'subscriptionPrice',
    subscriptionDate: 'subscriptionDate',
    initialDate: 'initialDate',
    upsellLevel: 'upsellLevel',
    name: 'name',
};
export const ClearFields = [
    ContractFields.subscriptionPrice,
    ContractFields.name,
    ContractFields.duration,
    ContractFields.mileage,
];

export const ContractsSliceStatus = {
    loading: 'loading',
    success: 'success',
    failed: 'failed',
};

export interface IContractSelections {
    duration?: string;
    mileage?: string;
    subscriptionPrice?: string;
    subscriptionDate?: string;
    name?: string;
    initialDate?: string;
    upsellLevel?: number | null;
}
