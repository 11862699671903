import React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';

import { StyledDatePicker, StyledInputLabel, SelectContainer } from './styles';
import { SubscriptionDateIcon } from '../../assets/icons';

interface ISelectProps {
    id: string;
    selectLabel: string;
    value: Date | null;
    setValue: (_: string | Date) => void;
    disabled?: boolean;
    fromDate?: string | moment.Moment;
    maxDate?: string | moment.Moment;
}

const defaultProps = {
    disabled: false,
    // Setting a default date to avoid errors while rendering the component
    fromDate: moment('2020-01-01'),
    maxDate: moment('2030-12-31'),
};

const SelectDate: React.FC<ISelectProps> = ({
    id,
    selectLabel,
    value,
    setValue,
    disabled,
    fromDate,
    maxDate,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <SelectContainer>
                <StyledInputLabel id={id}>{selectLabel}</StyledInputLabel>

                <StyledDatePicker
                    components={{
                        OpenPickerIcon: SubscriptionDateIcon,
                    }}
                    value={value}
                    mask="__/__/____"
                    inputFormat="DD/MM/YYYY"
                    minDate={moment(fromDate)}
                    maxDate={moment(maxDate)}
                    onChange={(selected) => setValue(String(selected))}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="textInput"
                            inputProps={{
                                ...params.inputProps,
                                placeholder: t(`pages.tierTool.cars.datePickerPlaceholder`),
                            }}
                            variant="outlined"
                        />
                    )}
                    disabled={disabled}
                    {...rest}
                />
            </SelectContainer>
        </LocalizationProvider>
    );
};
export default SelectDate;

SelectDate.defaultProps = defaultProps;
