import React from 'react';

import { normalizeName } from '../../utils/s3-images-helper';

export interface FileListProps {
    files: File[];
}

export const FileList = React.memo((props: React.PropsWithChildren<FileListProps>) => (
    <ol>
        {props.files.map((file: File) => (
            <li
                key={`${normalizeName(file.name)}_${file.lastModified}`}
                style={{ textAlign: 'left' }}
            >
                <span>{normalizeName(file.name)}</span>
                <span>({Math.round(file.size / 1000)}kb)</span>
            </li>
        ))}
    </ol>
));

FileList.displayName = 'FileList';
