import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Slices
import { fetchLissageContracts } from '../../../../../slices/carsSlice';
import { fetchLissageUpsells, updateSelections } from '../../../../../slices/contractsSlice';

// Components
import SelectDate from '../../../../../components/SelectDate/SelectDate';

// Types
import { ContractFields, ClearFields } from '../../../../../types/contracts';
import type { IContractSelections } from '../../../../../types/contracts';
import type { ICarsType } from '../../../../../types/cars';
import type { IBrandType } from '../../../../../types/brands';

// Utils
import { formatIsoDate, valueAsDate } from '../../../../../utils/formatFrenchDate';
import { indexToClearFrom } from '../../../../../utils/contract-helper';

export interface FormProps {
    formSelections: IContractSelections | null;
    selectedCar: ICarsType;
    selectedBrand: IBrandType;
}

const SelectSubscriptionDate: React.FC<FormProps> = (props: FormProps) => {
    const { selectedCar, selectedBrand, formSelections } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const clearSubsequentContractSelections = (updatedField: string) => {
        const initialIndex = indexToClearFrom(updatedField);
        for (let i = initialIndex; i < ClearFields.length; i++) {
            dispatch(updateSelections({ field: ClearFields[i], value: '' }));
        }
    };

    const handleSelectSubscriptionDate = (date: string) => {
        if (selectedCar.id && selectedBrand.id && formSelections?.initialDate) {
            const initialDate = formatIsoDate(formSelections.initialDate);
            const subscriptionDate = formatIsoDate(date);
            dispatch(
                fetchLissageUpsells({
                    brandId: selectedBrand.id,
                    carId: selectedCar.id,
                    initialDate,
                    subscriptionDate,
                }),
            );

            dispatch(
                fetchLissageContracts({
                    carId: Number(selectedCar.id),
                    initialDate,
                    subscriptionDate,
                }),
            );

            dispatch(
                updateSelections({
                    field: ContractFields.subscriptionDate,
                    value: String(date),
                }),
            );
            clearSubsequentContractSelections(ContractFields.subscriptionDate);
        }
    };

    return (
        <SelectDate
            id="subscriptionDate"
            selectLabel={t(`pages.tierTool.cars.subscriptionDateLabel`)}
            value={
                formSelections?.subscriptionDate
                    ? valueAsDate(formSelections.subscriptionDate)
                    : null
            }
            setValue={(date) => handleSelectSubscriptionDate(String(date))}
            disabled={!formSelections?.initialDate || formSelections.initialDate === ''}
            fromDate={formatIsoDate(formSelections?.initialDate)}
        />
    );
};

export default SelectSubscriptionDate;
