import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Alert, AlertTitle } from '@mui/material';

// Styles
import { Container } from './styles';

// Types
import type { IError, TranslationKeys } from '../../types/errors/index';
import { ErrorCodes } from '../../types/errors/index';

interface IItem extends IError {
    key?: TranslationKeys;
}

interface IProps {
    errors: Array<IItem | null>;
}

const ErrorAlert: React.FC<IProps> = ({ errors }) => {
    const { t } = useTranslation();
    return (
        <Container>
            <Alert severity="error">
                <AlertTitle>{t('global.error')}</AlertTitle>
                <ul>
                    {errors.map((error) => {
                        if (error) {
                            const { key, status, message, errorCode } = error;
                            if (errorCode) {
                                if (key) {
                                    return (
                                        <li key={key}>
                                            {t(`global.errors.${errorCode}.${key}`, {
                                                status,
                                                message,
                                            })}
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li key={errorCode}>
                                            {t(`global.errors.${errorCode}.message`, {
                                                status,
                                                message,
                                            })}
                                        </li>
                                    );
                                }
                            } else {
                                return (
                                    <li key={ErrorCodes.Unknown}>
                                        {t(`global.errors.${ErrorCodes.Unknown}`, {
                                            status,
                                            message,
                                        })}
                                    </li>
                                );
                            }
                        } else {
                            return null;
                        }
                    })}
                </ul>
            </Alert>
        </Container>
    );
};

export default ErrorAlert;
