import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// Slices
import { selectSelectedFullCar } from '../../../../../slices/carsSlice';

import {
    selectLissageUpsells,
    selectContractsLoading,
    updateSelections,
} from '../../../../../slices/contractsSlice';
import { updateSelectedUpsell } from '../../../../../slices/upsellsSlice';

// Components

import Select from '../../../../../components/Select/Select';

// Types
import { ContractFields, ClearFields } from '../../../../../types/contracts';
import type { IContractSelections } from '../../../../../types/contracts';
import type { ICarsType } from '../../../../../types/cars';
import type { IBrandType } from '../../../../../types/brands';

// Utils
import { indexToClearFrom, filterContracts } from '../../../../../utils/contract-helper';

export interface FormProps {
    formSelections: IContractSelections | null;
    selectedCar: ICarsType;
    selectedBrand: IBrandType;
}

const SelectContract: React.FC<FormProps> = (props: FormProps) => {
    const { formSelections } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Store
    const contractLoading = useSelector(selectContractsLoading);
    const selectedCar = useSelector(selectSelectedFullCar);

    const lissageUpsells = useSelector(selectLissageUpsells);

    const contractsOptions = useMemo(() => filterContracts(lissageUpsells), [lissageUpsells]);

    const clearSubsequentContractSelections = (updatedField: string) => {
        const initialIndex = indexToClearFrom(updatedField);
        for (let i = initialIndex; i < ClearFields.length; i++) {
            dispatch(updateSelections({ field: ClearFields[i], value: '' }));
        }
    };

    const handleSelectContract = (contractName: string) => {
        // Filter the array with available contract to the object for the selected contract name
        const selectedContract = lissageUpsells.find((option) => option.name === contractName);
        if (selectedCar && selectedContract) {
            dispatch(updateSelections({ field: ContractFields.name, value: contractName }));
            dispatch(
                updateSelections({
                    field: ContractFields.upsellLevel,
                    value: selectedContract.level,
                }),
            );
            dispatch(updateSelectedUpsell(selectedContract));
            clearSubsequentContractSelections(ContractFields.name);
        }
    };

    return (
        <Select
            id="selectContract"
            defaultValue={t(`global.dropdown.default`)}
            selectLabel={t(`pages.tierTool.cars.contractLabel`)}
            value={formSelections?.name ?? ''}
            options={contractsOptions}
            setValue={(contractName) => handleSelectContract(contractName)}
            disabled={
                contractLoading ||
                !formSelections?.subscriptionDate ||
                formSelections.subscriptionDate === ''
            }
            renderValue={(selected) => {
                if (!selected) {
                    return t(`global.dropdown.default`);
                }
                return String(selected);
            }}
            displayEmpty
        />
    );
};

export default SelectContract;
