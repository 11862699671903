import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// Slices
import { selectCarContracts, updateUpsellFormSelections } from '../../../../../slices/carsSlice';

// Components
import Select from '../../../../../components/Select/Select';

// Types
import { ContractFields } from '../../../../../types/contracts';
import type { IContractSelections } from '../../../../../types/contracts';

// Utils
import { renderSelectedMileage } from '../../../../../utils/formatDropdownValue';
import { filterMileageUpsell } from '../../../../../utils/upsell-helper';

export interface FormProps {
    formSelections: IContractSelections | null;
}

const SelectMileage: React.FC<FormProps> = (props: FormProps) => {
    const { formSelections } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Store
    const availableCarContracts = useSelector(selectCarContracts);

    const availableMileage = useMemo(
        () => filterMileageUpsell(availableCarContracts, formSelections, t),
        [availableCarContracts, formSelections, t],
    );

    const handleContractFieldSelection = (field: string, value: string) => {
        dispatch(updateUpsellFormSelections({ field, value }));
    };

    return (
        <Select
            id="selectMileage"
            selectLabel={t(`pages.tierTool.cars.mileageLabel`)}
            value={formSelections?.mileage ? String(formSelections.mileage) : ''}
            options={availableMileage}
            setValue={(mileage) =>
                handleContractFieldSelection(ContractFields.mileage, String(mileage))
            }
            disabled={!formSelections?.duration || formSelections.duration === ''}
            renderValue={(selected) => {
                if (selected) {
                    return renderSelectedMileage(selected, formSelections?.duration, t);
                }
                return t(`global.dropdown.default`);
            }}
            displayEmpty
        />
    );
};

export default SelectMileage;
