export interface SingleEvent<T> {
    content: T;
    hasBeenHandled: boolean;
}

export interface IFullWidthProp {
    fullWidth?: boolean;
}

export function consumeSingleEvent<T>(event: SingleEvent<T>): SingleEvent<T> {
    return { content: event.content, hasBeenHandled: true };
}

export function createSingleEvent<T>(content: T, hasBeenHandled = false): SingleEvent<T> {
    return { content, hasBeenHandled };
}
export interface FileTypes {
    message: string;
}
export const AcceptedFileTypes = {
    PNG: 'png',
    CSV: 'csv',
};

export interface IDropdownTypes {
    label: string | number;
    value: string | number;
}
export const SliceStatus = {
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILED: 'failed',
    UPLOADING: 'uploading',
};
