// UpsellsList
export type UpsellId = number;
export type ModifiedUpsellsState = Record<number, boolean>;
export interface INewServiceDialogStateProps {
    isOpen: boolean;
    upsellId: UpsellId | null;
}

// ServicesList
export enum ServiceKeys {
    NAME = 'name',
    COLOUR = 'hexCode',
    DISPLAY_PRIORITY = 'displayPriority',
    DESCRIPTION = 'description',
}

export type ServiceId = number;

export type ServiceRef = {
    id: ServiceId;
    name: string | null;
    colour: string | null;
    description: string | null;
    displayPriority: string | null;
};
export type ServicesRefArray = ServiceRef[];
export type ModifiedServicesStates = Record<number, boolean>;

// Styled components Props
export interface InputWrapperProps {
    size?: number;
    position?: 'start' | 'middle' | 'end';
}
