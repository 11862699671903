import styled from 'styled-components';

// Constants
import { colours } from '../../constants/colours';

// Types
import type { IHeaderNameProps, IChevronWrapperProps } from './types';

//
export const AccordionContainer = styled.div``;

export const AccordionWrapper = styled.div`
    margin: 1.25rem 0;
`;

export const HeaderName = styled.a<IHeaderNameProps>`
    width: 100%;
    padding: 0.5rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    background: ${({ colorHexCode }: IHeaderNameProps) => colorHexCode ?? colours.darkGrey}1A;
    border-bottom: 1px solid ${colours.blue};
    color: ${({ colorHexCode }: IHeaderNameProps) => colorHexCode};
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
`;

export const HeaderNameText = styled.div`
    flex: 2;
    text-align: center;

    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    p {
        margin: 0;
    }
`;

export const ChevronUpOutlineWrapper = styled.div<IChevronWrapperProps>`
    width: 1.5rem;
    transform: ${({ isExpanded }: IChevronWrapperProps) =>
        isExpanded ? 'rotate(180deg)' : 'unset'};
`;

export const ContentText = styled.div`
    width: 100%;
    padding: 0.5rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${colours.darkGrey};
`;
