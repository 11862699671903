import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components';
import Accordion from '../../../../components/Accordion';
import ActionButton from '../../../../components/ActionButton/ActionButton';

// Assets
import { AddCircleIcon, RemoveCircleIcon } from '../../../../assets/icons';

// Styles
import {
    AccordionContainer,
    ButtonsContainer,
    CustomButton,
    ModifyButtonCustomStyle,
    PrintButtonCustomStyle,
} from '../styles';
import type { IServiceType } from '../../../../types/services';

export interface ContractDetailsProps {
    translationBase: string;
    initialServices: IServiceType[];
    handleRecalculate: () => void;
}

const ContractDetails: React.FC<ContractDetailsProps> = (props: ContractDetailsProps) => {
    const { t } = useTranslation();

    const { translationBase, initialServices, handleRecalculate } = props;
    // State
    const [displayAllServices, setDisplayAllServices] = useState(false);
    const [services, setServices] = useState<IServiceType[]>(initialServices);
    const handleNavigateBack = () => {
        handleRecalculate();
    };
    const onExpandChange = (updatedService: IServiceType) => {
        let allExpanded = Boolean(updatedService.expanded);
        const updatedServices = services.map((service) => {
            if (service.id === updatedService.id) {
                if (updatedService.expanded === false) {
                    allExpanded = false;
                }
                return updatedService;
            }
            if (service.description && service.name) {
                if (service.expanded === false) {
                    allExpanded = false;
                }
            }

            return service;
        });
        setServices(updatedServices);
        setDisplayAllServices(allExpanded);
    };

    const toggleExpandAll = (toggle: boolean) => {
        setDisplayAllServices(toggle);
        const updatedServices = services.map((service) => {
            if (service.description && service.name) {
                service.expanded = toggle;
            }
            return service;
        });
        setServices(updatedServices);
    };

    return (
        <>
            <AccordionContainer>
                {services.map((service) => (
                    <Accordion
                        key={service.id}
                        service={service}
                        accordionOpened={(updatedService) => onExpandChange(updatedService)}
                        defaultExpanded={service.expanded}
                    />
                ))}
            </AccordionContainer>
            <ButtonsContainer>
                <ActionButton
                    title={t('global.printButton')}
                    disabled={false}
                    submitData={() => window.print()}
                    customStyle={PrintButtonCustomStyle}
                />
                <ActionButton
                    title={t('global.modifyButton')}
                    disabled={false}
                    submitData={() => handleNavigateBack()}
                    customStyle={ModifyButtonCustomStyle}
                />

                {/* Added a custom button  here since the icon on this one is at the right side */}
                {/* background color, size is different from ActionButton reusable component */}
                <CustomButton onClick={() => toggleExpandAll(!displayAllServices)}>
                    {displayAllServices
                        ? t(`${translationBase}.hideAllServicesButton`)
                        : t(`${translationBase}.displayAllServicesButton`)}
                    {displayAllServices ? <RemoveCircleIcon /> : <AddCircleIcon />}
                </CustomButton>
            </ButtonsContainer>
        </>
    );
};
export default ContractDetails;
