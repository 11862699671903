import styled, { css } from 'styled-components';

import { Select, InputLabel } from '@mui/material';

import { colours } from '../../constants/colours';

import type { IFullWidthProp } from '../../types/index';

interface ISelectProps extends IFullWidthProp {
    value: string | number;
}

export const SelectContainer = styled.div<IFullWidthProp>`
    display: inline-block;
    height: 72px;
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '600px')};
    width: 100%;

    #select {
        display: flex;
        align-items: center;
        max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '600px')};
        width: 100%;
        height: 40px;
        background: none;
        border: 1px solid ${colours.lightGrey};
        border-radius: 4px;
        padding: 0 16px;

        /* Dropdown Placeholder */
        p {
            color: ${colours.mediumGrey};
        }
    }

    /* Right space on dropdown svg icon */
    .MuiSvgIcon-root {
        margin-right: 10px;
    }

    .MuiInputBase-root {
        display: flex;
        width: 100%;
        max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '600px')};
        height: 42px;

        /* Removing the border before selecting an option */
        &:before {
            border: none;
        }

        /* Removing the border after selecting an option */
        &:after {
            border: none;
        }
    }

    .MuiInputBase-formControl {
        border: none;
        :hover {
            border: none;
        }
    }

    @media (max-width: 1225px) {
        max-width: 100%;
        width: 100%;
    } ;
`;

export const StyledInputLabel = styled(InputLabel)`
    height: 24px;
    color: ${colours.black};
    font-size: 16px;
    margin-bottom: 8px;

    @media (max-width: 1225px) {
        height: 24px;
        color: ${colours.black};
        font-size: 16px;
    } ;
`;

export const StyledSelect = styled(Select)<ISelectProps>`
    /* Remove border on select hover */
    && {
        :before {
            content: none;
        }
    }

    ${(props) =>
        props.value === '' &&
        css`
            color: ${colours.lightGrey};
            width: 100%;

            :hover {
                border: none;
                background: ${colours.white};
            }
        `}

    ${(props) =>
        props.value === 0 &&
        css`
            background-color: ${colours.lightGrey};
            border: none;

            :hover {
                background: ${colours.lightGrey};
            }
        `}

    @media (max-width: 1225px) {
        display: flex;
        align-items: center;
        max-width: 100%;
        height: 40px;
        background: none;
        /* Dropdown Placeholder */
        p {
            color: ${colours.mediumGrey};
        }
    } ;
`;
