import React from 'react';

import { Container } from './styles';

import stellantisLogo from '../../assets/stellantisLogo.svg';

const Header: React.FunctionComponent = () => (
    <Container data-testid="header">
        <img src={stellantisLogo} alt="Stellantis" />
    </Container>
);

export default Header;
