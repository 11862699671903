import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Slices
import {
    selectBrand,
    selectBrandLoading,
    selectBrandErrors,
    fetchBrandById,
} from '../../../slices/brandSlice';
import { selectUpsells, selectUpsellsLoading, fetchUpsells } from '../../../slices/upsellsSlice';
import {
    selectContracts,
    selectContractsLoading,
    fetchContractsBundle,
} from '../../../slices/contractsSlice';
import {
    selectSelectedFullCar,
    selectCarLoading,
    selectCarErrors,
    fetchCar,
    clearSelectedData,
} from '../../../slices/carsSlice';

// Utils

// Assets
import { ArrowBack } from '../../../assets/icons';

// Components
import Page from '../../../components/Page/Page';
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader';
import ErrorAlert from '../../../components/ErrorAlert/ErrorAlert';
import ActionButton from '../../../components/ActionButton/ActionButton';
import SubHeader from '../../../components/SubHeader/SubHeader';

// SubComponents
import Bundles from './Bundles/Bundles';
import Disclaimer from './Disclaimer/Disclaimer';

// Constants
import { APP_ROUTES } from '../../../constants/app.routes';

// Styles
import {
    Container,
    BackButtonWrapper,
    PageContent,
    BundlesContainer,
    Divider,
    RedoButtonContainer,
    RedoButton,
    StyledButton,
    DisclaimerContainer,
    PrintButtonCustomStyle,
} from './styles';

// Types
import { TranslationKeys } from '../../../types/errors/index';

const translationBase = 'pages.upsell.contracts';

const Contracts: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { brand, brandId, carId, duration, mileage } = useParams();

    // Store
    const brandLoading = useSelector(selectBrandLoading);
    const upsellLoading = useSelector(selectUpsellsLoading);
    const contractLoading = useSelector(selectContractsLoading);
    const carLoading = useSelector(selectCarLoading);

    const selectedBrand = useSelector(selectBrand);
    const selectedUpsells = useSelector(selectUpsells);
    const selectedContracts = useSelector(selectContracts);
    const selectedCar = useSelector(selectSelectedFullCar);

    const brandSelectionError = useSelector(selectBrandErrors);
    const carSelectionError = useSelector(selectCarErrors);

    const handleNavigateBack = useCallback(() => {
        dispatch(clearSelectedData());
        navigate(
            generatePath(APP_ROUTES.UPSELL_CARS, {
                brand: selectedBrand?.name ?? brand,
            }),
        );
    }, [brand, dispatch, navigate, selectedBrand?.name]);

    useEffect(() => {
        // Flush stored data when using browser back button
        window.onpopstate = () => {
            handleNavigateBack();
        };

        if (!selectedBrand && brandId) {
            dispatch(fetchBrandById({ brandId }));
        }
    }, [selectedBrand, brandId, dispatch, handleNavigateBack, navigate, brand]);

    useEffect(() => {
        if (brandId) {
            dispatch(fetchUpsells({ brandId }));
        }
    }, [brandId, dispatch]);

    useEffect(() => {
        if (carId) {
            dispatch(
                fetchContractsBundle({
                    carId,
                    duration: String(duration),
                    mileage: String(mileage),
                }),
            );
            if (!selectedCar) {
                dispatch(fetchCar(carId));
            }
        }
    }, [carId, duration, mileage, dispatch, selectedCar]);

    return (
        <>
            <Helmet>
                <title>{t('pages.upsell.contracts.title')}</title>
            </Helmet>
            <Page>
                <Container>
                    <BackdropLoader
                        open={brandLoading || upsellLoading || contractLoading || carLoading}
                    />
                    <BackButtonWrapper onClick={() => handleNavigateBack()}>
                        <ArrowBack />
                        <span>{t('pages.upsell.contracts.backButton')}</span>
                    </BackButtonWrapper>
                    {(brandSelectionError || carSelectionError) && (
                        <ErrorAlert
                            errors={[
                                brandSelectionError
                                    ? { key: TranslationKeys.Brand, ...brandSelectionError }
                                    : null,
                                carSelectionError
                                    ? { key: TranslationKeys.Car, ...carSelectionError }
                                    : null,
                            ]}
                        />
                    )}
                    {selectedBrand && selectedUpsells && selectedContracts && (
                        <PageContent>
                            <SubHeader
                                brand={selectedBrand.name}
                                title={t(`${translationBase}.title`)}
                                info={t(`${translationBase}.info`)}
                            />
                            <BundlesContainer>
                                {selectedCar?.carImage && (
                                    <Bundles
                                        selectedBrand={selectedBrand}
                                        selectedUpsells={selectedUpsells}
                                        selectedContracts={selectedContracts}
                                        carImage={selectedCar.carImage}
                                    />
                                )}
                            </BundlesContainer>

                            <Divider />

                            <RedoButtonContainer>
                                <ActionButton
                                    title={t('global.printButton')}
                                    disabled={false}
                                    submitData={() => window.print()}
                                    customStyle={PrintButtonCustomStyle}
                                />

                                <RedoButton>
                                    <StyledButton
                                        type="button"
                                        onClick={() => navigate(`/${selectedBrand.name}`)}
                                    >
                                        {t('global.modifyButton')}
                                    </StyledButton>
                                </RedoButton>
                            </RedoButtonContainer>

                            <DisclaimerContainer>
                                <Disclaimer
                                    selectedUpsells={selectedUpsells}
                                    selectedContracts={selectedContracts}
                                />
                            </DisclaimerContainer>
                        </PageContent>
                    )}
                </Container>
            </Page>
        </>
    );
};

export default Contracts;
