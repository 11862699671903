export interface ICarsForm {
    brandId: string;
    carId: string;
    carName: string;
    initialDate?: string;
    subscriptionDate?: string;
    contract?: string;
    duration: string;
    mileage: string;
}

export interface ICarsType {
    id: number;
    fullName: string;
    shortName: string;
    carImage: string | null;
}

export const CarSliceStatus = {
    loading: 'loading',
    success: 'success',
    failed: 'failed',
};

export interface CarStepper {
    id: number;
    title: string;
    showCarCard?: boolean;
}

export const CarStepperOld = [
    {
        id: 0,
    },
    {
        id: 1,
    },
];

export interface ISelectedCar {
    carName: string;
    carId: string;
}
