export const widths = {
    xsmall: 375,
    small: 480,
    medium: 768,
    large: 992,
    xlarge: 1200,
    xxlarge: 1400,
    xxxlarge: 1600,
};

export const minSizes = {
    xsmall: `(min-width: ${widths.xsmall}px)`,
    small: `(min-width: ${widths.small}px)`,
    medium: `(min-width: ${widths.medium}px)`,
    large: `(min-width: ${widths.large}px)`,
    xlarge: `(min-width: ${widths.xlarge}px)`,
    xxlarge: `(min-width: ${widths.xxlarge}px)`,
    xxxlarge: `(min-width: ${widths.xxxlarge}px)`,
};

export const maxSizes = {
    xsmall: `(max-width: ${widths.xsmall - 1}px)`,
    small: `(max-width: ${widths.small - 1}px)`,
    medium: `(max-width: ${widths.medium - 1}px)`,
    large: `(max-width: ${widths.large - 1}px)`,
    xlarge: `(max-width: ${widths.xlarge - 1}px)`,
    xxlarge: `(max-width: ${widths.xxlarge - 1}px)`,
    xxxlarge: `(max-width: ${widths.xxxlarge - 1}px)`,
};
