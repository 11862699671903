import React from 'react';
import { useTranslation } from 'react-i18next';

// Libs
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Constants
import { colours } from '../../constants/colours';

import CarsCard from '../CarsCard/CarsCard';

// Types
import type { ICarsType, CarStepper } from '../../types/cars';

// Styles
import {
    StyledStepper,
    StyledStep,
    StyledStepLabel,
    StepperNavigationBar,
    StepperLabelContainer,
    StepText,
    SelectedCarContainer,
    StyledStepContent,
    StepperArrowsContainer,
    Separator,
} from './styles';

interface IStepperProps {
    // TODO: Update to a correct type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- using any to render the children component
    children: any;
    activeStep: number;
    stepConstants: CarStepper[];
    edit: boolean;
    handleBackStep: () => void;
    displayCheckMark: boolean;
    selectedCar: ICarsType | null;
}

const Stepper: React.FC<IStepperProps> = (props: IStepperProps) => {
    const { t } = useTranslation();
    const { activeStep, stepConstants, edit, handleBackStep, displayCheckMark, selectedCar } =
        props;

    return (
        <StyledStepper
            activeStep={activeStep}
            orientation="vertical"
            id="stepper"
            data-testid="stepper"
        >
            {stepConstants.map((step: CarStepper) => (
                <StyledStep key={`step-${step.id}`}>
                    <StyledStepLabel>
                        <StepperNavigationBar disabled={activeStep < step.id}>
                            <StepperLabelContainer>
                                <StepText>{t(step.title)}</StepText>
                                {/* Show CHECK icon to the right of the Stepper Label if the Step is completed */}
                                {(activeStep > step.id ||
                                    (activeStep === step.id && displayCheckMark)) && (
                                    <CheckCircleOutlineOutlinedIcon
                                        className="stepCompletedIcon"
                                        sx={{ fontSize: 24 }}
                                    />
                                )}
                            </StepperLabelContainer>
                            {/* Conditional rendering of Arrows on right corner of Step title (description) */}

                            {activeStep === step.id && (
                                <KeyboardArrowUpIcon sx={{ color: colours.blue, fontSize: 24 }} />
                            )}
                            {/* Arrow DOWN and MODIFY text */}
                            {step.id === 0 && activeStep === 1 && (
                                <StepperArrowsContainer
                                    id="stepperArrowsContainer"
                                    onClick={() => handleBackStep()}
                                >
                                    <p>{t('pages.upsell.cars.modifierButton')}</p>
                                    <KeyboardArrowDownIcon
                                        sx={{ color: colours.blue, fontSize: 24 }}
                                    />
                                </StepperArrowsContainer>
                            )}
                        </StepperNavigationBar>
                    </StyledStepLabel>

                    <Separator />

                    {/* DISPLAY ONLY IF CAR IS SELECTED AND AFTER STEP 1 */}
                    {step.showCarCard && selectedCar && !edit && (
                        <SelectedCarContainer>
                            <CarsCard
                                carIsSelected={Boolean(selectedCar)}
                                selectedFullCar={selectedCar}
                            />
                        </SelectedCarContainer>
                    )}

                    <StyledStepContent>{props.children}</StyledStepContent>
                </StyledStep>
            ))}
        </StyledStepper>
    );
};
export default Stepper;
