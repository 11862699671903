import styled from 'styled-components';

import { colours } from '../../../constants/colours';

export const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
`;

export const StepperContainer = styled.div`
    margin: 0 48px;
    max-width: 1224px;
    width: 100%;
`;

export const FormContainer = styled.div`
    display: inline-block;
    max-width: 1224px;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0;
`;

export const Footer = styled.footer`
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 48px;
    background: ${colours.blue};
    align-items: center;
    justify-content: center;

    p {
        color: ${colours.white};
        justify-content: center;
        text-align: center;
        font-size: 12px;
        font-family: 'Encode Sans';
        font-style: normal;
    }

    /* To fit in the Table Screen */
    @media (max-width: 1280px) and (max-height: 720px) {
        position: relative;
    }
`;
