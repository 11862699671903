import styled from 'styled-components';
import { Paper, TextField, Button } from '@mui/material';

import { colours } from '../../constants/colours';

export const Container = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
});

export const PaperContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    margin: 2rem auto;
`;
export const TextfieldContainer = styled(TextField)`
    // Default styling overwritten with
    margin-bottom: 1rem !important;
`;
export const ButtonContainer = styled(Button)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    margin: 2rem auto;
`;
export const FormLogin = styled.form({
    textAlign: 'center',
});
export const ErrorText = styled.p`
    color: ${colours.red};
`;
export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 31.25rem;
    min-width: 22rem;
    > * {
        margin-bottom: 1.25rem;
    }
`;

export const Title = styled.h2``;

export const Text = styled.h5``;
