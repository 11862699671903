export enum TranslationKeys {
    Brand = 'brand',
    Upsell = 'upsell',
    Service = 'service',
    Car = 'car',
    Contract = 'contract',
    Login = 'login',
}

export enum ErrorCodes {
    InvalidName = 'InvalidName',
    InvalidID = 'InvalidID',
    InvalidCredentials = 'InvalidCredentials',
    Unknown = 'Unknown',
    MissingFiles = 'MissingFiles',
    DBFailure = 'DBFailure',
    CSVUpdateFailure = 'CSVUpdateFailure',
    ImageSubmission = 'ImageSubmission',
}

export interface IError {
    status: number | string;
    message: string;
    errorCode?: ErrorCodes;
}

export const ErrorMessages = {
    invalidCredentials: 'Request failed with status code 401',
};
