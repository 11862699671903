import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// MUI
import {
    Button,
    FormControl,
    TextField,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
} from '@mui/material';

// Slices
import { refreshUpsellServices } from '../../../slices/upsellsSlice';
import {
    selectDeletedServiceId,
    selectUpdatedService,
    selectServicesErrors,
    doUpdateService,
    doDeleteService,
} from '../../../slices/servicesSlice';

// Assets

// Constants

// Component
import ActionButton from '../../../components/ActionButton/ActionButton';
import MarkdownEditor from '../../../components/MarkdownEditor/MarkdownEditor';

// SubComponents

// Helpers
import { parseNull, parseDefaultString } from '../helper';

// Styles
import {
    ActionButtonsContainer,
    FormContainer,
    FormWrapper,
    InputsLineContainer,
    InputWrapper,
} from '../styles';

// Types
import { ServiceKeys } from '../types';
import type { IUpsellType } from '../../../types/upsells';
import type { ServiceId, ServiceRef, ModifiedServicesStates } from '../types';
import type { IServiceType } from '../../../types/services';

interface IServicesListProps {
    upsell: IUpsellType;
}

const ServicesList: React.FC<IServicesListProps> = ({ upsell }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Stores
    const deletedServiceId = useSelector(selectDeletedServiceId);
    const updatedService = useSelector(selectUpdatedService);
    const servicesErrors = useSelector(selectServicesErrors);

    // States
    const [sortedUpsellServices, setSortedUpsellServices] = useState<IServiceType[] | null>(null);
    const [modifiedServices, setModifiedServices] = useState<ModifiedServicesStates>({});
    const [updatingServiceId, setUpdatingServiceId] = useState<ServiceId | null>(null);
    const [deletingService, setDeletingService] = useState<ServiceRef | null>(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

    // Refs
    const servicesNames = useRef<Record<number, string | null>>({});
    const servicesColours = useRef<Record<number, string | null>>({});
    const servicesDisplayPriorities = useRef<Record<number, string | null>>({});
    const servicesDescriptions = useRef<Record<number, string | null>>({});

    // Helpers
    const getServiceRef = (id: ServiceId, key: ServiceKeys) => {
        switch (key) {
            case ServiceKeys.NAME:
                return servicesNames.current[id];
            case ServiceKeys.COLOUR:
                return servicesColours.current[id];
            case ServiceKeys.DISPLAY_PRIORITY:
                return servicesDisplayPriorities.current[id];
            case ServiceKeys.DESCRIPTION:
                return servicesDescriptions.current[id];
            default:
                throw new Error('Invalid key');
        }
    };

    const setServiceRef = (id: ServiceId, key: ServiceKeys, value: string | null) => {
        switch (key) {
            case ServiceKeys.NAME:
                servicesNames.current[id] = value;
                break;
            case ServiceKeys.COLOUR:
                servicesColours.current[id] = value;
                break;
            case ServiceKeys.DISPLAY_PRIORITY:
                servicesDisplayPriorities.current[id] = value;
                break;
            case ServiceKeys.DESCRIPTION:
                servicesDescriptions.current[id] = value;
                break;
            default:
                throw new Error('Invalid key');
        }
    };

    const getServiceRefObject = (id: ServiceId) => {
        const name = getServiceRef(id, ServiceKeys.NAME);
        const colour = getServiceRef(id, ServiceKeys.COLOUR);
        const displayPriority = getServiceRef(id, ServiceKeys.DISPLAY_PRIORITY);
        const description = getServiceRef(id, ServiceKeys.DESCRIPTION);
        return {
            id,
            name,
            colour,
            displayPriority,
            description,
        };
    };

    const doResetServicesRefs = () => {
        servicesNames.current = {};
        servicesColours.current = {};
        servicesDisplayPriorities.current = {};
        servicesDescriptions.current = {};
    };

    // Effects
    useEffect(() => {
        doResetServicesRefs();

        // Initiate all service refs with their current values
        if (upsell.relatedServices) {
            upsell.relatedServices.forEach((service) => {
                setServiceRef(service.id, ServiceKeys.NAME, service.name);
                setServiceRef(service.id, ServiceKeys.COLOUR, service.hexCode);
                setServiceRef(
                    service.id,
                    ServiceKeys.DISPLAY_PRIORITY,
                    String(service.displayPriority),
                );
                setServiceRef(service.id, ServiceKeys.DESCRIPTION, service.description);
            });

            setSortedUpsellServices(
                [...upsell.relatedServices].sort((a, b) => b.displayPriority - a.displayPriority),
            );
        }
    }, [upsell]);

    useEffect(() => {
        if (updatedService && updatingServiceId && updatedService.id === updatingServiceId) {
            setUpdatingServiceId(null);
            setModifiedServices({
                ...modifiedServices,
                [updatedService.id]: false,
            });
        }
    }, [updatedService, updatingServiceId, modifiedServices]);

    useEffect(() => {
        if (deletedServiceId && deletingService && deletingService.id === deletedServiceId) {
            setDeletingService(null);
            dispatch(refreshUpsellServices({ upsellId: upsell.id }));
            setConfirmDialogOpen(false);
        } else if (deletingService) {
            setConfirmDialogOpen(true);
        }
    }, [deletedServiceId, deletingService, dispatch, upsell.id]);

    useEffect(() => {
        if (servicesErrors) {
            setUpdatingServiceId(null);
            setDeletingService(null);
        }
    }, [servicesErrors]);

    // Handlers
    const handleServiceModified = (id: ServiceId, key: ServiceKeys, value: string) => {
        if (!modifiedServices[id]) {
            setModifiedServices({
                ...modifiedServices,
                [id]: true,
            });
        }

        setServiceRef(id, key, value);
    };

    const handleDoUpdateService = (id: ServiceId) => {
        if (updatingServiceId) return;
        setUpdatingServiceId(id);
        const serviceRef = getServiceRefObject(id);

        dispatch(
            doUpdateService({
                serviceId: id,
                name: parseNull(serviceRef.name),
                hexCode: parseNull(serviceRef.colour),
                description: parseNull(serviceRef.description),
                displayPriority: Number(parseDefaultString(serviceRef.displayPriority, '1')),
            }),
        );
    };

    const handleDoDeleteService = (id: ServiceId) => {
        const serviceRef = getServiceRefObject(id);
        setDeletingService(serviceRef);
    };

    // Manual Dialog closing handler, resets deleting & close dialog
    const handleCloseDialog = () => {
        setDeletingService(null);
        setConfirmDialogOpen(false);
    };

    const handleDeleteConfirmation = () => {
        if (deletingService) {
            dispatch(doDeleteService({ serviceId: deletingService.id }));
        }
    };

    // Render
    return (
        <div>
            {sortedUpsellServices
                ? sortedUpsellServices.map((service) => (
                      <FormContainer key={`${service.id}-${service.displayPriority}`}>
                          <FormControl margin="dense" fullWidth>
                              <FormWrapper>
                                  <InputsLineContainer>
                                      <InputWrapper size={2} position="start">
                                          <MarkdownEditor
                                              label={t('pages.admin.inputsLabels.serviceName')}
                                              defaultValue={service.name}
                                              onChange={(value: string) =>
                                                  handleServiceModified(
                                                      service.id,
                                                      ServiceKeys.NAME,
                                                      value,
                                                  )
                                              }
                                          />
                                      </InputWrapper>
                                      <InputWrapper size={1} position="end">
                                          <TextField
                                              label={t('pages.admin.inputsLabels.displayPriority')}
                                              type="number"
                                              defaultValue={service.displayPriority}
                                              margin="dense"
                                              onChange={(e) =>
                                                  handleServiceModified(
                                                      service.id,
                                                      ServiceKeys.DISPLAY_PRIORITY,
                                                      e.target.value,
                                                  )
                                              }
                                              fullWidth
                                          />

                                          <TextField
                                              label={t('pages.admin.inputsLabels.color')}
                                              defaultValue={service.hexCode}
                                              margin="dense"
                                              InputLabelProps={{
                                                  shrink: true,
                                              }}
                                              onChange={(e) =>
                                                  handleServiceModified(
                                                      service.id,
                                                      ServiceKeys.COLOUR,
                                                      e.target.value,
                                                  )
                                              }
                                              fullWidth
                                          />
                                      </InputWrapper>
                                  </InputsLineContainer>
                                  <InputsLineContainer>
                                      <InputWrapper>
                                          <MarkdownEditor
                                              label={t(
                                                  'pages.admin.inputsLabels.serviceDescription',
                                              )}
                                              defaultValue={service.description}
                                              onChange={(value: string) =>
                                                  handleServiceModified(
                                                      service.id,
                                                      ServiceKeys.DESCRIPTION,
                                                      value,
                                                  )
                                              }
                                          />
                                      </InputWrapper>
                                  </InputsLineContainer>
                              </FormWrapper>
                              <ActionButtonsContainer>
                                  <ActionButton
                                      title={t('pages.admin.actionButtons.delete')}
                                      iconName="delete"
                                      submitData={() => handleDoDeleteService(service.id)}
                                      disabled={Boolean(deletingService)}
                                  />
                                  {modifiedServices[service.id] && (
                                      <ActionButton
                                          title={t('pages.admin.actionButtons.save')}
                                          iconName="save"
                                          submitData={() => handleDoUpdateService(service.id)}
                                          disabled={Boolean(updatingServiceId)}
                                      />
                                  )}
                              </ActionButtonsContainer>
                          </FormControl>
                      </FormContainer>
                  ))
                : null}

            <Dialog
                open={confirmDialogOpen}
                onClose={() => handleCloseDialog()}
                aria-labelledby="confirm-dialog"
                maxWidth="md"
            >
                <DialogTitle id="confirm-dialog">{t('pages.admin.confirmationTitle')}</DialogTitle>
                <DialogContent>
                    {t('pages.admin.confirmationText')}
                    <br />
                    {deletingService ? (
                        <pre>
                            {t('pages.admin.actions.deletingService.title')}
                            <br />
                            {t('pages.admin.actions.deletingService.id', {
                                deletingServiceId: deletingService.id,
                            })}
                            <br />
                            {t('pages.admin.actions.deletingService.name', {
                                deletingServiceName: deletingService.name,
                            })}
                            <br />
                            {t('pages.admin.actions.deletingService.description', {
                                deletingServiceDescription: deletingService.description,
                            })}
                            <br />
                        </pre>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => handleCloseDialog()}>
                        {t('pages.admin.actionButtons.cancel')}
                    </Button>
                    <Button variant="contained" onClick={() => handleDeleteConfirmation()}>
                        {t('pages.admin.actionButtons.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ServicesList;
