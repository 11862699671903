import type { PayloadAction } from '@reduxjs/toolkit';

import type { IUpsellUpdateResponseSuccess } from '../slices/upsellsSlice';
import type { IUpsellType } from '../types/upsells/index';

export const updateUpsellStateObject = (
    upsellState: IUpsellType[],
    action: PayloadAction<IUpsellUpdateResponseSuccess>,
) => {
    const newUpsellState = [...upsellState];

    const targetUpsellIndex = newUpsellState.findIndex(
        (item) => item.id === action.payload.data.id,
    );
    if (targetUpsellIndex >= 0) {
        newUpsellState[targetUpsellIndex] = {
            ...newUpsellState[targetUpsellIndex],
            ...action.payload.data,
        };
    }

    return newUpsellState;
};
