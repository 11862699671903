import type { Action } from '@reduxjs/toolkit';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';

import carsReducer from './slices/carsSlice';
import brandReducer from './slices/brandSlice';
import upsellsReducer from './slices/upsellsSlice';
import contractsReducer from './slices/contractsSlice';
import servicesReducer from './slices/servicesSlice';
import authReducer from './slices/authSlice';
import fileReducer from './slices/fileSlice';

const rootReducer = combineReducers({
    cars: carsReducer,
    brand: brandReducer,
    upsells: upsellsReducer,
    contracts: contractsReducer,
    services: servicesReducer,
    auth: authReducer,
    file: fileReducer,
});

const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export default store;
