import React from 'react';

import { defaultCarImage } from '../../constants/cars';
import { s3ImagesURL } from '../../utils/s3-images-helper';

interface IProps {
    name?: string | null;
    onErrorSrc?: string | null;
}

const S3Image: React.FC<IProps> = (props) => {
    const { name, onErrorSrc } = props;

    return (
        <img
            src={`${s3ImagesURL()}${name}.png`}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = onErrorSrc ?? defaultCarImage;
            }}
            alt={`${name}.png`}
            title={`${name}.png`}
        />
    );
};

S3Image.defaultProps = {
    name: null,
    onErrorSrc: null,
};

export default S3Image;
