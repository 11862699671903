import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const FieldContainer = styled.div`
    display: block;
    padding: 0;
    margin: 0;
`;

export const FieldTitle = styled.p`
    color: ${colours.black};
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.5rem;
`;

export const FieldLabel = styled.p`
    display: flex;
    align-items: top;
    color: ${colours.darkGrey};
    margin-top: 0.5rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1rem;

    svg {
        width: 16px;
        height: 16px;
    }

    > svg {
        margin-right: 0.6rem;
    }
`;
