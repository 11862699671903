import type { TFunction } from 'react-i18next';

import { ContractFields } from '../types/contracts';
import type { IContractSelections, IContractType } from '../types/contracts';
import type { ICarsType } from '../types/cars';
import type { IBrandType } from '../types/brands';
import type { IUpsellType } from '../types/upsells';
import { formatIsoDate } from './formatFrenchDate';
import { renderDurationOptionLabel, renderMileageOptionLabel } from './formatDropdownValue';

export const indexToClearFrom = (updatedFeild: string) => {
    switch (updatedFeild) {
        case ContractFields.initialDate:
            return 0;
        case ContractFields.subscriptionDate:
            return 1;
        case ContractFields.name:
            return 2;
        case ContractFields.duration:
            return 3;
        default:
            return 0;
    }
};

export const lissageCarsPageComplete = (
    selections: IContractSelections | null,
    car: ICarsType | null,
    upsell: IUpsellType | null,
) => Boolean(car && upsell && selections && formComplete(selections));

export const formComplete = (selections: IContractSelections) =>
    Boolean(
        selections.initialDate &&
            selections.subscriptionDate &&
            selections.duration &&
            selections.mileage &&
            selections.name,
    );

export const generatePathParams = (
    brand: IBrandType,
    car: ICarsType,
    selections: IContractSelections,
    upsell: IUpsellType,
) => {
    const contractURL = selections.name ? selections.name.replaceAll(' ', '_') : selections.name;
    return {
        brand: brand.name.toLowerCase(),
        brandId: String(brand.id),
        carId: String(car.id),
        initialDate: String(selections.initialDate),
        subscriptionDate: String(selections.subscriptionDate),
        duration: String(selections.duration),
        mileage: String(selections.mileage),
        upsellId: String(upsell.id),
        name: contractURL,
    };
};

export const fetchUpsellsParams = (
    brand: IBrandType,
    car: ICarsType,
    selections: IContractSelections,
) => ({
    brandId: brand.id,
    carId: car.id,
    initialDate: formatIsoDate(selections.initialDate ?? ''),
    subscriptionDate: formatIsoDate(selections.subscriptionDate ?? ''),
});

export const fetchContractsParams = (car: ICarsType, selections: IContractSelections) => ({
    carId: Number(car.id),
    initialDate: formatIsoDate(selections.initialDate ?? ''),
    subscriptionDate: formatIsoDate(selections.subscriptionDate ?? ''),
});

export const filterMileage = (
    contracts: IContractType[],
    upsell: IUpsellType | null,
    selections: IContractSelections | null,
    t: TFunction<'translation'>,
) =>
    contracts
        .filter(
            (contract) =>
                // NOTE: Removed upsellLevel filter since contract upsellLevel and upsell.Level doesn't match for some contracts
                contract.name === upsell?.name &&
                String(contract.duration) === selections?.duration,
        )
        .map(({ mileage }) => ({
            label: renderMileageOptionLabel(mileage, t),
            value: mileage,
        }))
        .filter(
            (mileage, index, self) =>
                self.findIndex((item) => item.value === mileage.value) === index,
        );

export const filterDuration = (
    contracts: IContractType[],
    upsell: IUpsellType | null,
    t: TFunction<'translation'>,
) =>
    contracts
        // NOTE: Removed upsellLevel filter since contract upsellLevel and upsell.Level doesn't match for some contracts
        .filter((contract) => contract.name === upsell?.name)
        .map(({ duration }) => ({
            label: renderDurationOptionLabel(duration, t),
            value: duration,
        }))
        .filter(
            (duration, index, self) =>
                self.findIndex((item) => item.value === duration.value) === index,
        )
        .sort((a, b) => a.value - b.value);

export const filterContracts = (upsells: IUpsellType[]) =>
    upsells
        .map((option) => ({
            label: option.name,
            value: option.name,
            contractDisplayPriority: option.contractDisplayPriority,
        }))
        .sort((a, b) => {
            let orderA = 99;
            let orderB = 99;

            if (a.contractDisplayPriority !== null) {
                orderA = a.contractDisplayPriority;
            }
            if (b.contractDisplayPriority !== null) {
                orderB = b.contractDisplayPriority;
            }

            return orderA - orderB;
        });
