import type { TFunction } from 'react-i18next';

// Function to render the values with text in select Duration component
export const renderSelectedDuration = (
    optionSelected: string | number | unknown,
    storedDuration: string | number | undefined,
    t: TFunction<'translation'>,
) => {
    if (String(optionSelected) === '') {
        return t(`global.dropdown.default`);
    } else {
        return t('global.dropdown.durationSelected', {
            durationByMonth: storedDuration,
            duration: Number(storedDuration) / 12,
        });
    }
};

// Render the option label for duration dropdown
export const renderDurationOptionLabel = (option: number, t: TFunction<'translation'>) =>
    t('global.dropdown.durationOptions', { durationByMonth: option });

// Function to render the values with text in select Mileage component
export const renderSelectedMileage = (
    optionSelected: string | number | unknown,
    storedDuration: string | number | undefined,
    t: TFunction<'translation'>,
) => {
    if (storedDuration && optionSelected) {
        if (String(optionSelected) === '') {
            return String(t(`global.dropdown.default`));
        } else {
            return t('global.dropdown.mileageSelected', {
                mileage: Number(optionSelected).toLocaleString('fr'),
                mileagePerYear: Math.round(
                    (Number(optionSelected) / Number(storedDuration)) * 12,
                ).toLocaleString('fr'),
            });
        }
    }
    return '';
};

// Render the option label for mileage dropdown
export const renderMileageOptionLabel = (option: number, t: TFunction<'translation'>) =>
    t('global.dropdown.mileageOptions', { mileage: option.toLocaleString('fr') });
