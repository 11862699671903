import React from 'react';

import { useTranslation } from 'react-i18next';

// Helpers & Constants
import { colours } from '../../../../constants/colours';

// Utils

import { getContractPrice } from '../../../../utils/calculateContractPrice';

// Styles
import { PriceTagWrapper, PriceTag, Price } from '../styles';

export interface PriceTagProps {
    duration: string | null;
    initialDate: string | null;
    subscriptionDate: string | null;
    subscriptionPrice: string | null;
    hexCode: string | null;
}

const PriceDisplay: React.FC<PriceTagProps> = (props: PriceTagProps) => {
    const { t } = useTranslation();

    const { hexCode, initialDate, subscriptionDate, duration, subscriptionPrice } = props;

    return (
        <PriceTagWrapper>
            <PriceTag bgColorHexCode={hexCode ?? colours.defaultPriceTagColor}>
                {initialDate && subscriptionDate && duration && subscriptionPrice && (
                    <Price>
                        {getContractPrice(
                            initialDate,
                            subscriptionDate,
                            String(subscriptionPrice),
                            duration,
                        )}
                        {` ${t('global.currencySymbol')} ${t(
                            'pages.tierTool.contract.priceTagAdditional',
                        )}`}
                    </Price>
                )}
            </PriceTag>
        </PriceTagWrapper>
    );
};
export default PriceDisplay;
