import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, StyledLink } from './styles';

const Footer: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <StyledLink href={t(`global.footerLink`)} target="_blank" rel="noreferrer noopener">
                {t('global.footerText')}
            </StyledLink>
        </Container>
    );
};

export default Footer;
