export const colours = {
    blue: '#243882',
    secondary: '#4e5d9a',
    white: '#ffffff',
    black: '#1A1A1A',
    offWhite: '#f4f5f9',

    lightestBlue: '#f0f2fa',
    lightBlue: '#b0c6f7',
    darkBlue: '#1A2960',
    darkerBlue: '#131E47',
    defaultPriceTagColor: '#00A3E0',

    darkerGrey: '#363636',
    darkGrey: '#474747',
    mediumGrey: '#909090',
    lightGrey: '#BBBBBB',
    lightestGrey: '#fafafa',

    activeBlue: '#1976d2',
    success: '#008F1A',
    red: '#AD0040',
    warning: '#EEB123',
    warning2: '#E66C3B',
};
