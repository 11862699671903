import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MarkdownRenderer from '../../../../components/MarkdownRenderer/MarkdownRenderer';

// Utils
import { parseYear } from '../../../../utils/parseTime';

// Styles
import { LegalNotice } from './styles';

// Types
import type { ILegalNoticeProps } from '../types';

const Disclaimer: React.FC<ILegalNoticeProps> = ({ selectedUpsells, selectedContracts }) => {
    const { t } = useTranslation();

    const legalNotices: () => string[] = useCallback(
        () =>
            selectedUpsells.map((upsell) => {
                const contract = selectedContracts.find(
                    (item) => item.upsellLevel === upsell.level,
                );
                if (contract) {
                    return `${t('pages.upsell.contracts.legalNoticeSeparator')} ${
                        upsell.name
                    } - ${parseYear(contract.duration)} ${t('global.years')} (${
                        contract.duration
                    } ${t('global.month')}) - ${contract.mileage}${t('global.km')} ${
                        upsell.legal ? `, ${upsell.legal}` : ''
                    }`;
                } else {
                    return '';
                }
            }),
        [selectedUpsells, selectedContracts, t],
    );

    return (
        <LegalNotice>
            <MarkdownRenderer
                content={`${t('pages.upsell.contracts.legal')} ${legalNotices().join('')}`}
            />
        </LegalNotice>
    );
};

export default Disclaimer;
