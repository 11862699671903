import styled from 'styled-components';

import { colours } from '../../constants/colours';

interface FillerProps {
    uploadProgress: number;
}

export const Container = styled.div`
    display: flex;
    justify-self: center;
    height: 20px;
    width: 100%;
    max-width: 600px;
    background-color: ${colours.lightGrey};
    border-radius: 50px;
`;
export const Filler = styled.div`
    height: 100%;
    width: ${({ uploadProgress }: FillerProps) =>
        uploadProgress > 0 ? `${uploadProgress}%` : null};
    border-radius: inherit;
    background-color: ${colours.blue};
    text-align: right;
`;

export const ProgressLabel = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 20px;
    color: ${colours.white};
    font-weight: bold;
`;
