import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../axiosInstance';

// Types
import type { RootState } from '../store';
import type { IContractType, IContractSelections } from '../types/contracts/index';
import type { IError } from '../types/errors/index';
import type { IUpsellType } from '../types/upsells';

// Routes
import { BACKEND_ROUTES } from '../constants/api.routes';

export interface IContractsState {
    contracts: IContractType[] | null;
    dropdownUpsells: IUpsellType[];
    contract: IContractType | null;
    formSelections: IContractSelections | null;
    errors: IError | null;
    loading: boolean;
}

export interface IResponseFailure extends IError {}

export interface ContractsSuccess {
    data: IContractType[];
}

export interface LissageDropdownSuccess {
    data: IUpsellType[];
}

export interface ContractSuccess {
    data: IContractType;
}
export interface IUpdateItem {
    field: string;
    value: string | number | null;
}
export const initialState: IContractsState = {
    contracts: null,
    dropdownUpsells: [],
    contract: null,
    formSelections: null,
    errors: null,
    loading: false,
};

export const fetchContractsBundle = createAsyncThunk(
    '/car/:car_id/contracts-bundle',
    (
        params: { carId: string; duration: string; mileage: string; upsellLevel?: string },
        { dispatch },
    ) => {
        axiosInstance
            .get<ContractsSuccess>(`${BACKEND_ROUTES.CARS}/${params.carId}/contracts-bundle`, {
                params: {
                    duration: params.duration,
                    mileage: params.mileage,
                    ...(params.upsellLevel ? { upsellLevel: Number(params.upsellLevel) } : {}),
                },
            })
            .then((response) => {
                dispatch(setContractsAction(response.data.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const fetchLissageUpsells = createAsyncThunk(
    'lissage/fetchLissageUpsells',
    (
        params: { brandId: number; carId: number; initialDate: string; subscriptionDate: string },
        { dispatch },
    ) => {
        axiosInstance
            .get<LissageDropdownSuccess>(
                `${BACKEND_ROUTES.CARS}/${params.carId}/available-lissage-contracts`,
                {
                    params: {
                        brandId: params.brandId,
                        initialDate: params.initialDate,
                        subscriptionDate: params.subscriptionDate,
                    },
                },
            )
            .then((response) => {
                dispatch(setLissageDropdownContractsAction(response.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const fetchContractByParams = createAsyncThunk(
    'lissage/fechSingleContract',
    (
        params: {
            carId: string;
            contractName: string;
            duration: string;
            mileage: string;
            carName: string;
        },
        { dispatch },
    ) => {
        axiosInstance
            .post<ContractSuccess>(BACKEND_ROUTES.CONTRACT_BY_PARAMS, { params })
            .then((response) => {
                dispatch(setSelectedSingleContractAction(response.data.data));
            })
            .catch((err: IResponseFailure) => {
                dispatch(setErrorsAction(err));
            });
    },
);

export const updateSelectedContract = createAsyncThunk(
    'lissage/setSelectedContract',
    (contract: IContractType | null, { dispatch }) => {
        dispatch(setSelectedSingleContractAction(contract));
    },
);

export const updateContractsBundle = createAsyncThunk(
    'lissage/setContractsBundle',
    (contracts: IContractType[] | null, { dispatch }) => {
        dispatch(setContractsAction(contracts));
    },
);

export const updateSelections = createAsyncThunk(
    'lissage/setSelectedContract',
    (params: { field: string; value: string | number | null }, { dispatch }) => {
        dispatch(setContractSelectionsAction(params));
    },
);
export const clearContractSelection = createAsyncThunk(
    'lissage/setSelectedContract',
    (_, { dispatch }) => {
        dispatch(clearContractSelections());
    },
);

export const slice = createSlice({
    name: 'contracts',
    initialState,
    reducers: {
        setContractsAction(state, action: PayloadAction<IContractType[] | null>) {
            state.contracts = action.payload;
            state.loading = false;
            state.errors = null;
        },
        setLissageDropdownContractsAction(state, action: PayloadAction<LissageDropdownSuccess>) {
            state.dropdownUpsells = action.payload.data;
            state.loading = false;
        },
        setSelectedSingleContractAction(state, action: PayloadAction<IContractType | null>) {
            state.contract = action.payload;
        },
        setContractSelectionsAction(state, action: PayloadAction<IUpdateItem>) {
            const key = action.payload.field;
            if (state.formSelections) {
                const previous = state.formSelections;
                state.formSelections = { ...previous, [key]: action.payload.value };
            } else {
                state.formSelections = { [action.payload.field]: action.payload.value };
            }
        },
        clearContractSelections(state) {
            state.formSelections = null;
            state.contract = null;
        },
        setErrorsAction(state, action: PayloadAction<IResponseFailure | null>) {
            state.contracts = null;
            state.errors = action.payload;
            state.loading = false;
        },
        clearContractsAction(state) {
            state.contracts = null;
            state.loading = false;
            state.errors = null;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchLissageUpsells.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchContractsBundle.pending, (state) => {
            state.loading = true;
        });
    },
});

export const selectContractsLoading = (state: RootState) => state.contracts.loading;
export const selectContracts = (state: RootState) => state.contracts.contracts;
export const selectLissageUpsells = (state: RootState) => state.contracts.dropdownUpsells;

export const selectContract = (state: RootState) => state.contracts.contract;
export const selectContractSelections = (state: RootState) => state.contracts.formSelections;
export const {
    setContractsAction,
    setErrorsAction,
    clearContractsAction,
    setLissageDropdownContractsAction,
    setSelectedSingleContractAction,
    setContractSelectionsAction,
    clearContractSelections,
} = slice.actions;

export default slice.reducer;
